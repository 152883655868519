<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>
  <ModalSubirArchivo ref="modalsubirarchivo" />

  <div class="column-right">
    <div class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        <!--<h2 style="margin-bottom:20px;">Profesionales <b-icon icon="person-fill"></b-icon></h2>-->

          

          <div   class="card shadow p-4 border-0 mb-7">

                        <h5 class="mb-0">  </h5>

                        <div class="sectionv">
                          <h2>Información personal 📋</h2>  
                          <div><b>Nombre</b>: {{ profesional.nombre + ' '+ profesional.apellido }}</div>
                          <div><b>Email</b>: {{ profesional.email }}</div>
                          <div><b>Teléfono</b>: {{ profesional.telefono }}</div>
                          <div><b>Calificación</b>: <span v-if="profesional.clasificacion[0] != 0"><span v-for="s in Math.trunc(profesional.clasificacion[1]/profesional.clasificacion[0])"> <b-icon class="star" icon="star-fill"></b-icon></span> 

<span v-if="Math.trunc(profesional.clasificacion[1]/profesional.clasificacion[0]) != profesional.clasificacion[1]/profesional.clasificacion[0]"> <b-icon class="star" :icon="profesional.clasificacion[1]/profesional.clasificacion[0] - Math.floor(profesional.clasificacion[1]/profesional.clasificacion[0])>= 0.5 ? 'star-half' : 'star'"></b-icon></span> 
<span v-for="s in Math.trunc(5-profesional.clasificacion[1]/profesional.clasificacion[0])"> <b-icon class="star" icon="star"></b-icon></span> </span> 
<span style="font-style: italic;" v-else> No tiene</span>
                        </div>
                        <div>
                            <button v-on:click="actualizar_password_profesional()"
style="margin-right:5px"  class="btn btn-std mt-2"><b-icon icon="code"></b-icon> Reiniciar password
</button>
                          </div>
                      </div>
             
                        <div class="sectionv">
                          <h2>Horarios ⏰</h2>
              
              <table style="width: 100%; border: 1px solid rgb(38,53,66);">

                <tr style="background-color:rgb(38,53,66); color:#FFF;">
                  <td>Horario</td>
                  <td>Comunas</td>
                  <td>Servicios</td>
                  <td>Periodo</td>
                  <td>Anticipación necesaria</td>
                  <td>Opciones</td>
                </tr>
                <tr style="border: 1px solid rgb(38,53,66);" v-for="h in profesional.horarios">
                  <td>
                    <span v-if="i == '1'" v-for="i, r in h.repeticion">{{ dias2[r] }}, </span>
                    {{ timeConvert(h.inicio) }} -
                    {{ timeConvert(h.termino) }}
                  </td>
                  <td>
                    <div v-for="c in h.comunas">{{c.nombre }}</div>
                  </td>
                  <td>
                    <div v-for="s in h.servicios">{{s.nombre }}</div>
                  </td>
                  <td>
                    {{ h.tipo == 1 ? 'Indefinido' : 'No'}}
                  </td>
                  <td>
                    {{ timeConvert(h.anticipacion)}} hrs
                  </td>
                  <td>
                    <b-icon @click="$router.push({name:'ModificarHorarioPro', params:{id: h.id}})"  style="cursor: pointer; margin-right:5px;" icon="pen"></b-icon>
                    <b-icon @click="eliminar_horario(h.id)" style="cursor: pointer;" icon="trash"></b-icon>
                  </td>
                
                </tr>
              </table>
              <router-link style="margin-right:5px" :to="{name:'NuevoHorarioPro', params:{id:profesional.id}}" class="btn btn-std mt-2"><b-icon icon="plus-circle"></b-icon> Agregar nuevo horario</router-link>

                        </div>

                        <div class="sectionv">
                          <h2 style="">Notificaciones 🔔</h2>


<div  v-for="c in notificaciones">
  <input  v-model="c.active" type="checkbox"> {{ c.nombre }}
</div>
<button v-on:click="actualizar_notificaciones()"
style="margin-right:5px"  class="btn btn-std mt-2"><b-icon icon="plus-circle"></b-icon> Guardar notificaciones
</button>
                        </div>
                        <div class="sectionv">
                          <h2 style="">Archivos 📁</h2>
                          <table v-if="profesional.archivos.length" class="archivos-table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Categoría</th>
          <th>Tipo</th>
          <th>Fecha Creado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="archivo in profesional.archivos" :key="archivo.id">
          <td>{{ archivo.nombre }}</td>
          <td>{{ archivo.categoria }}</td>
          <td>{{ archivo.tipo.toUpperCase() }}</td>
          <td>{{ formatDate(archivo.fecha_creado) }}</td>
          <td>
            <button class="btn-std btn" style="margin-right: 5px;" @click="descargarArchivo(archivo)">Descargar</button>
            <button class="eliminar-btn" @click="eliminarArchivo(archivo.id)">Eliminar</button>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>No hay archivos disponibles.</p>

<button v-on:click="$refs.modalsubirarchivo.show(profesional)"
style="margin-right:5px"  class="btn btn-std mt-2"><b-icon icon="plus-circle"></b-icon> Agregar archivo
</button>
                        </div>

                    <div class="sectionv"  v-if="admin_datos.superadmin">

                      <h2 style="">Respuestas automáticas 🤖</h2>
                        <div v-if="profesional.auto_respuesta == 1">
                          El bot de auto respuestas se encuentra <b>activado</b> para {{ profesional.nombre }}.

                        </div>
                        <div v-else>
                          El bot de auto respuestas se encuentra <b>desactivado</b> para {{ profesional.nombre }}.

                        </div>
                        <div v-on:click="cambiar_autorespuesta(profesional.id)" class="btn-activacion" :class="{'btn-activado': profesional.auto_respuesta == 1, 'btn-desactivado': profesional.auto_respuesta != 1}">
                            {{ profesional.auto_respuesta == 1 ? 'Desactivar' : 'Activar' }}
                        </div>
                        <div style="margin-top:10px;" v-if="profesional.auto_respuesta == 1">
                          Puedes agregar información extra para que el bot conteste al cliente
                          <textarea v-model="profesional.informacion_escondida" class="styled-textarea" placeholder="Información para el bot"></textarea>
                          <button v-on:click="guardar_informacion(profesional.id)"
                      style="margin-right:5px"  class="btn btn-std mt-2"><b-icon icon="save"></b-icon> Guardar información
                    </button>
                        </div>

                    </div>

                      <div class="sectionv">

                        <h2 >Bloqueos 🚫</h2>
                      <table style="width: 100%; border: 1px solid rgb(38,53,66); ">

                        <tr style="background-color:rgb(38,53,66); color:#FFF;">
                          <td>Horario</td>
                          <td>Dia</td>
                          <td>Opciones</td>
                        </tr>
                        <tr style="border: 1px solid rgb(38,53,66);" v-for="h in profesional.bloqueos">
                          <td>
                            
                            {{ timeConvert(h.desde) }} -
                            {{ timeConvert(h.hasta) }}
                          </td>
                          <td>
                            {{new Date(h.fecha.a, h.fecha.m-1, h.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}
                          </td>

                          <td>
                            <b-icon @click="eliminar_bloqueo(h.id)" style="cursor: pointer;" icon="trash"></b-icon>
                          </td>
                        
                        </tr>
                      </table>

                      <router-link :to="{name:'NuevoBloqueo', params:{id:profesional.id}}" class="btn btn-std mt-2"><b-icon icon="plus-circle"></b-icon> Agregar bloqueo</router-link>
                        

                      </div>

                      <div class="sectionv">

                        <h2>Precios 🏷️</h2>
                      <router-link :to="{name:'ModificarPrecios', params:{profesional_id:profesional.id}}" class="btn btn-std mt-2"><b-icon icon="cash"></b-icon> Modificar precios</router-link>
                       

                      </div>  

                        
                        
                      <div class="sectionv">

<h2>Calendario 📅</h2>
<div style="max-width: 1400px;">
           <table style="width:100%;">
                    <td>
                    <button style="margin-right:6px;" class="btn btn-std" v-on:click="semanaAnterior();">  <b-icon icon="arrow-left-square" style="margin-right:6px;" /> Semana Anterior </button>
                   </td>
                   <td style="text-align:center;"> <h2 style="font-size: 20px;"> Semana del {{desdeD.getDate()}} de {{meses[desdeD.getMonth()]}} del {{desdeD.getFullYear()}}</h2></td>
                   <td style="text-align:right;">
                    <button class="btn btn-std" v-on:click="semanaSiguiente()">  Semana Siguiente <b-icon icon="arrow-right-square" style="margin-left:6px;" /></button>
                  </td>

                  </table>
            <div  style="padding:0px; margin-bottom:40px; position:relative; margin-top:0px;" class="shadow-object">
              <div v-if="!loaded_calendario" style="position:absolute; height: 100%; width:100%; background:rgba(0,0,0,0.4); z-index:999;">
                  <div style="position:absolute; top:50%; color:white; left:50%;">
                    <div class="spinner-border spinner-border-sm"  role="status"></div>
                    Cargando...
                      
                  </div>
              </div>

                    <table class="Table-Normal" style="width:100%; text-align:center;">
                      <td style="width:12.5%; font-size: 14px; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space:nowrap;  border:1px solid white; border-bottom: 1px solid rgba(0,0,0,0.6); ">Horario</td>
                      <td  style="width:12.5%; font-size: 14px; overflow: hidden; text-align: center; text-overflow: ellipsis; white-space:nowrap; border:1px solid white;border-bottom: 1px solid rgba(0,0,0,0.6); " v-for="d, i in dias2">{{d}} {{viendoSemana[i]}}</td>
                    
                    </table>
                
                    <table style="width:12.5%; text-align:center; ">
                      <!--display: -webkit-box-->
                      <tr v-for="i in Math.ceil((horaMax-horaMin)/60)" 
                       style="text-align:center; font-size:10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-height:50px; height:50px; line-height:48px;  border-right:1px solid rgba(0,0,0,0.6); "
                      >
                        {{timeConvert(horaMin+(i-1)*60)}}-{{timeConvert(horaMin+(i)*60)}}

                      </tr>
                    </table>
                    <div v-for="i in Math.ceil((horaMax-horaMin)/60)" :style="{'top': 25+(50*i)+'px'}" style="position: absolute;  left:0px; width: 100%; border-bottom: 1px solid rgba(0,0,0,0.3);">

</div>
                    <template v-for="h in profesional.horarios"  >
                    <div v-for="r, ind in h.repeticion" v-if="r == 1"
                      :style="{
                      'left':(12.5*(ind+1)).toString()+'%', 'line-height':(50*(h.inicio-h.termino)/60)+'px', 
                      'height':(50*(h.termino-h.inicio)/60)+'px', 'top':(((h.inicio-horaMin)*50/60)+25).toString()+'px',
                      'background':'repeating-linear-gradient(45deg,#606dbc,#606dbc 10px,#465298 10px,#465298 20px)'
                      }" 
                      style="font-size:12px; position:absolute; vertical-align:middle; text-align:center; color:white; width:12.5%; opacity: 0.7;">
                       <div style="height:100%; position: relative;">
                        <div style="position: absolute; top:50%; width: 100%; transform: translateY(-50%) rotate(45deg); text-align: center;">
                          Horario Disponible
                        </div>
                      </div>
                      </div>
                    </template>

                <div v-for="h in profesional.bloqueos" :key="h.id"   v-if="new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) >= desdeD && new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) <= hastaD" 
                      @click="$router.push({name:'ModificarHorario', params:{id:h.id}})"
                      :style="{
                      'left':(12.5*(viendoSemana.findIndex(x=>{return x == h.fecha.d})+1)).toString()+'%', 'line-height':(50*(h.hasta-h.desde)/60)+'px', 
                      'height':(50*(h.hasta-h.desde)/60)+'px', 'top':(((h.desde-horaMin)*50/60)+25).toString()+'px',
                      'background':'#FF4136',
                      }" 
                      style="font-size:12px; border-radius: 5px; cursor: pointer; border:1px solid #FFF; position:absolute; vertical-align:middle; text-align:center; color:white; width:12.5%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; ">
                        Horario Bloqueado
       
                    </div>
                <div v-for="h in ordenes" :key="h.id"   v-if="new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) >= desdeD && new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) <= hastaD" 
                      @click="$router.push({name:'ModificarHorario', params:{id:h.id}})"
                      :style="{
                      'left':(12.5*(viendoSemana.findIndex(x=>{return x == h.fecha.d})+1)).toString()+'%', 'line-height':(50*(h.hasta-h.desde)/60)+'px', 
                      'height':(50*(h.hasta-h.desde)/60)+'px', 'top':(((h.desde-horaMin)*50/60)+25).toString()+'px',
                      'background': h.estado == 2 ? 'green' : 'black',
                      }" 
                      style="font-size:12px; border-radius: 5px; cursor: pointer; border:1px solid #FFF; position:absolute; vertical-align:middle; text-align:center; color:white; width:12.5%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; ">
                        Hora reservada
       
                    </div>
            </div>

          </div>

</div>  


                
          
        
   










          </div>
  </div>
    </div>









  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalNuevoProfesional from '@/components/ModalNuevoProfesional.vue';
import ModalSubirArchivo from '@/views/superadmin/ModalSubirArchivo.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    ModalNuevoProfesional,
    ModalSubirArchivo,
    TopNav,
  },
  data() {
    return {
      loaded:false,

      profesional:undefined,


      loaded_calendario:false,

      hastaD:0,
      desdeD:0,

      viendoAño:0,
      viendoDia:0,
      viendoMes:0,

      hoyMes:0,
      hoyDia:0,
      hoyAño:0,


      ordenes:[],

      mapping:[6, 0, 1, 2, 3, 4, 5],
      meses:['Enero', 'Febrero', 'Marzo', 'Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Nobiembre','Diciembre'],
      dias:['Lu', 'Ma', 'Mi', 'Jue', 'Vi', 'Sa', 'Do'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],


      notificaciones:[],
    }
  },
  created(){
  
    this.initialize_();
    this.hoy = new Date();
    this.hoyMes = this.hoy.getMonth();
    this.hoyDia = this.hoy.getDate();  
    this.hoyAño = this.hoy.getFullYear();
    this.desdeD = new Date()
    this.desdeD.setDate(this.desdeD.getDate() - this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))
    this.desdeD.setHours(0,0,0,0);
    this.hastaD = new Date()
    this.hastaD.setDate(this.hastaD.getDate() + 6-this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))
    this.hastaD.setHours(0,0,0,0);
    this.viendoDia = this.hoyDia;
    this.viendoMes = this.hoyMes;
    this.viendoAño = this.hoyAño
    this.ver_calendario()
  },

  methods:{
    ...mapActions(['initialize']),
    getDia(año, mes, dia){
      return this.mapping[new Date(año, mes, dia).getDay()];
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('es-ES', options);
    },

    eliminar_horario(hid){
      axios.post(window.hostname+'api/admin/eliminar_horario', 
        {horario_id: hid})
        .then(response => 
        {
          this.$notify({duration:5000, group: 'foos', title: 'Horario eliminado', text: '¡Se ha eliminado el horario!', type: 'success'})
            this.initialize_();
        })

        .catch(e=>{
            this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
            console.log("Error al cargar...")
        })

    },

    eliminarArchivo(aid) {
  // Mostrar un cuadro de confirmación antes de eliminar el archivo
  if (confirm('¿Estás seguro de que deseas eliminar este archivo?')) {
    // Si el usuario confirma, procede con la eliminación
    axios.post(window.hostname + 'api/superadmin/profesional_eliminar_archivo', 
      { archivo_id: aid })
      .then(response => {
        this.$notify({
          duration: 5000, 
          group: 'foos', 
          title: 'Archivo eliminado', 
          text: '¡Se ha eliminado el archivo!', 
          type: 'success'
        });
        this.initialize_(false);  // Volver a inicializar la lista
      })
      .catch(e => {
        this.$notify({
          duration: 5000, 
          group: 'foos', 
          title: '¡Error!', 
          text: e.response.data.message, 
          type: 'error'
        });
        console.log("Error al eliminar el archivo...");
      });
  } else {
    // Si el usuario cancela, no se hace nada
    this.$notify({
      duration: 3000,
      group: 'foos',
      title: 'Eliminación cancelada',
      text: 'No se ha eliminado el archivo.',
      type: 'info'
    });
  }
},
    descargarArchivo(archivo){
      axios({
        url: window.hostname+'api/superadmin/obtener_link_archivo_profesional',
        method: 'POST',
        data: {
          archivo_id:archivo.id,
        }
      })
      .then( (response) =>  {

        const anchor = document.createElement('a');

// Set the href attribute to the download URL
anchor.href = response.data.link;

// Set the download attribute to the filename
anchor.download = archivo.nombre+'.'+response.data.ext;

  // Add the anchor element to the document
  document.body.appendChild(anchor);

  // Trigger a click event on the anchor element
  anchor.click();

  // Remove the anchor element from the document
  document.body.removeChild(anchor);         
      })
      .catch(e=>{
        console.log(e);
      })

    },
    cambiar_autorespuesta(hid){
      axios.post(window.hostname+'api/superadmin/cambiar_autorespuesta', 
        {profesional_id: hid})
        .then(response => 
        {
          this.$notify({duration:5000, group: 'foos', title: 'Actualizado', text: '¡Se ha actualizado la autorespuesta!', type: 'success'})
            this.initialize_(false);
        })

        .catch(e=>{
            this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
            console.log("Error al cargar...")
        })

    },
    guardar_informacion(hid){
      axios.post(window.hostname+'api/superadmin/guardar_informacion_escondida', 
        {profesional_id: hid, informacion:this.profesional.informacion_escondida})
        .then(response => 
        {
          this.$notify({duration:5000, group: 'foos', title: 'Actualizado', text: '¡Se ha actualizado la autorespuesta!', type: 'success'})
            this.initialize_();
        })

        .catch(e=>{
            this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
            console.log("Error al cargar...")
        })

    },
    eliminar_bloqueo(hid){
      axios.post(window.hostname+'api/admin/eliminar_bloqueo', 
        {bloqueo_id: hid})
        .then(response => 
        {
          this.$notify({duration:5000, group: 'foos', title: 'Bloqueo eliminado', text: '¡Se ha eliminado el bloqueo!', type: 'success'})
            this.initialize_();
        })

        .catch(e=>{
            this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
            console.log("Error al cargar...")
        })

    },
    actualizar_notificaciones(){
      let cats = []
      for(var c of this.notificaciones){
        if (c.active){
          cats.push({'categoria_id':c.id})
        }
      }
      axios.post(window.hostname+'api/admin/modificar_config_notificaciones', 
        {id: this.profesional.id, categorias:cats})
        .then(response => 
        {
          this.$notify({duration:5000, group: 'foos', title: 'Notificaciones actualizadas', text: '¡Se han actualizado las notificaciones!', type: 'success'})
            this.initialize_();
        })

        .catch(e=>{
            this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})
            console.log("Error al cargar...")
        })

    },
    actualizar_password_profesional() {
  // Prepare data with only the professional ID
  let data = {
    profesional_id: this.profesional.id
  };

  // Send a POST request to the API endpoint
  axios.post(window.hostname + 'api/admin/establecer_password_profesional', data)
    .then(response => {
      // Notify success
      this.$notify({
        duration: 5000,
        group: 'foos',
        title: 'Contraseña Actualizada',
        text: '¡Se ha actualizado la contraseña del profesional!',
        type: 'success'
      });
    })
    .catch(error => {
      // Notify error if the request fails
      this.$notify({
        duration: 5000,
        group: 'foos',
        title: '¡Error!',
        text: error.response ? error.response.data.message : 'Error al actualizar la contraseña',
        type: 'error'
      });
      console.log("Error al actualizar la contraseña:", error);
    });
},
    semanaSiguiente(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()+7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()+7)   
      this.hastaD = H
      this.ver_calendario();
    },
    semanaAnterior(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()-7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()-7)   
      this.hastaD = H
      this.ver_calendario();
    },
    getDia(año, mes, dia){
      return this.mapping[new Date(año, mes, dia).getDay()];
    },
    ver_calendario()
    {
      if (typeof(this.$route.params.id === 'number'))
          {
            this.loaded_calendario = false;
            axios.post(window.hostname+'api/admin/ver_calendario_pro', 
            {profesional_id: this.$route.params.id,
            dia:this.desdeD.getDate(),
            mes:this.desdeD.getMonth()+1,
            año:this.desdeD.getFullYear(),
            dia2:this.hastaD.getDate(),
            mes2:this.hastaD.getMonth()+1,
            año2:this.hastaD.getFullYear(),
            })
            .then(response => 
            {
                this.loaded_calendario = true;
                this.ordenes = response.data.ordenes
                //this.categorias = response.data.categorias
            })
            .catch(e=>{
                this.loaded_calendario = true;
                console.log(e, "Error al cargar...")
            })
          }
        },


    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    initialize_(set_loading=true){
      if (typeof(this.$route.params.id === 'number'))
      {
        if(set_loading)
        this.loaded = false;
        axios.post(window.hostname+'api/admin/getprofesional', {id:this.$route.params.id})
        .then(response => 
        {
            this.loaded = true;
            this.profesional = response.data.profesional;
            this.notificaciones = [];
            for(var i = 0; i < this.categorias.length; i++){
              this.notificaciones.push({id:this.categorias[i].id, nombre:this.categorias[i].nombre, active: this.profesional.notificaciones.some(x=>x.categoria_id == this.categorias[i].id)})
            }
   
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...", e)
        })
      }
      if(!set_loading){
        this.$forceUpdate();
      }
    },
  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),


    horaMin: function(){
      var min = 360;
      /*
      if (this.profesional != undefined)
      {
        for(var i = 0; i<this.profesional.horarios.length; i++)
        {
          for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
          {
            if (this.profesional.horarios[i].ordenes[j].desde < min)
            {
              min = this.profesional.horarios[i].ordenes[j].desde
            }
          }
        }
      }
      */
      return min
    },
    horaMax: function(){
      var max = 1320;
      /*
      if (this.profesional != undefined)
      {
        for(var i = 0; i<this.profesional.horarios.length; i++)
        {
          for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
          {
            if (this.profesional.horarios[i].ordenes[j].desde > max)
            {
              max = this.profesional.horarios[i].ordenes[j].desde
            }
          }
        }
      }
      */
      return max
    },


    viendoSemana: function(){
      var o;
      var f = [new Date(this.viendoAño, this.viendoMes-1, this.desdeD.getDate())];
      var ret = [this.desdeD.getDate()]
      for (var i = 1; i < 7; i++)
      {
        o = new Date(f[i-1].getFullYear(), f[i-1].getMonth(), f[i-1].getDate())
        o.setDate(o.getDate() + 1)
        f.push(o)
        ret.push(o.getDate())
      }
      return ret;
     
    },
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
    this.$root.$on('update-profesionales', () => {      
      this.initialize_(false)
    
    })

  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
  }

}
</script>


<style scoped>
.btn-std{
  background-color: #007bff !important;

}
.eliminar-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.eliminar-btn:hover {
  background-color: #c0392b;
}

.btn-std:hover{
  background-color: #52a5ff !important;
}
.star{
  cursor: pointer;
  margin-right:4px;
  height:18px;
  width: 18px;
  color:#DAA520;
  /*color:#DAA520;*/
}
h2{
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}
.sectionv{
  background-color: rgb(250,250,250);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}
        .styled-textarea {
            display: block;
            width: 100%;
            max-width: 600px;
            height: 200px;
            padding: 10px;
            border: 2px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
            line-height: 1.5;
            background-color: #fff;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
            resize: vertical;
        }

        .styled-textarea:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
            outline: none;
        }

.btn-activacion{
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  color:#fff;
  cursor: pointer;
  margin-top: 5px;
}
.btn-activado{
  background-color: #FF4136;
}
.btn-desactivado{
  background-color: #007bff;

}

td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}
table
{ 
}

.column-right{
  background-color: rgb(242, 247, 251);
  min-height:100vh;
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}

}
.archivos-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
  text-align: left;
}

.archivos-table th, .archivos-table td {
  padding: 12px 15px;
  
}

.archivos-table th {


  letter-spacing: 0.03em;
}

.archivos-table tr:nth-child(even) {

}














/* Calendario */

</style>

