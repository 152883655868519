<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.user_type === 'property_manager'" class="main-container">
        <div v-if="loaded" class="articulo-principal">
          <!-- Header con Título y Filtro de Fecha -->
          <div class="header-container">
            <h2>Solicitudes de Clientes</h2>
            <div class="date-filter-container">
              <label for="dateFilter" class="date-filter-label">Filtrar por fecha:</label>
              <select id="dateFilter" v-model="dateFilter" @change="fetchSolicitudes" class="date-filter-select">
                <option value="today">Hoy</option>
                <option value="last_7_days">Últimos 7 días</option>
                <option value="last_30_days">Últimos 30 días</option>
                <option value="last_2_months">Hace dos meses</option>
                <option value="last_6_months">Últimos 6 meses</option>
                <option value="last_12_months">Últimos 12 meses</option>
                <option value="all">Todos</option>
              </select>
            </div>
          </div>
        
          Puedes arrastrar la tabla hacia los lados con el cursor para ver más columnas.
           <!-- Search Bar -->
           <div style="margin-top: 10px;">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Buscar en comuna, título, estado, dirección o descripción..."
              style="width: 100%; padding: 8px; font-size: 16px;"
            />
          </div>

          <div
            class="table-container"
            ref="tableContainer"
            @mousedown="dragStart"
            @mousemove="dragMove"
            @mouseup="dragEnd"
            @mouseleave="dragEnd"
            @touchstart="dragStart"
            @touchmove="dragMove"
            @touchend="dragEnd"
            @touchleave="dragEnd"
            @touchcancel="dragEnd"
          >
            <table class="tablex">
              <thead>
                <tr>
                  <!-- Table Headers -->
                  <th>ID</th>
                  <th>Fecha Solicitud</th>
                  <th>Título</th>
                  <th>Dirección</th>
                  <th>Comentarios</th>
                  <th>Estado</th>
                  <th>Porcentaje completado</th>
                  <th>Atendido por</th>
                  <th>Fecha Visita</th>
                  <th>Fecha Envío Presupuesto</th>
                  <th>Fecha Aprobación</th>
                  <th>Fecha Inicio</th>
                  <th>Fotos</th>
                  <th>Videos</th>
                  <th>Comprobante de Pago</th>
                  <th>Factura al Cliente</th>
                  <th>Monto Total</th>
                  <th>Monto Pagado</th>
                  <th><span style="white-space: nowrap;">Monto por Cobrar</span> al Cliente</th>
                  <th v-if="hasMargenCaptacion()"><span style="white-space: nowrap;">Monto por Cobrar</span> a Jobbing</th>
                  <th v-if="hasMargenCaptacion()">Factura hacia Jobbing</th>
                  <th>Fecha Calificación</th>
                  <th>Calificación</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(solicitud, index) in filteredSolicitudes">
                  <tr
                    :key="solicitud.id"
                    @click="toggleDetails('details' + index, $event)"
                    @contextmenu.prevent="openContextMenu($event, solicitud)"
                  >
                    <!-- Table Data -->
                    <td>{{ solicitud.id }}</td>
                    <td>{{ extractDate(solicitud.fecha_creado).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}</td>
                    <td>{{ solicitud.titulo }}</td>
                    <td>{{ solicitud.direccion }}, {{ solicitud.comuna_nombre }}</td>
                    <td><div v-html="getComentarios(solicitud)"></div></td>
                    <td><div style="font-weight: 700;" :style="{'color':solicitud.estado_front.color}"> {{ solicitud.estado_front.estado }} </div></td>
  
                    <td>{{solicitud.estado_front.porcentaje}}%</td>
                    <td>{{ getAtendidoPor(solicitud) }}</td>
                    <td>{{ getFechaVisita(solicitud) }}</td>
                    <td>{{ getFechaEnvioPresupuesto(solicitud) }}</td>
                    <td> <div v-html="getFechaAprobacion(solicitud)"></div></td>
                    <td> <div v-html="getFechaInicio(solicitud)"></div></td>
                    <td> <div v-html="hasFotos(solicitud) ? getFotosAntes(solicitud).length+' fotos de antes y '+getFotosDespues(solicitud).length+' fotos de después' : '<span style=\'white-space:nowrap;\' >No hay<span/> fotos'"></div></td>
                    <td>
                      <div v-if="getVideo(solicitud) === 'Descargar'">
                        <div v-for="archivo in solicitud.orden.archivos.filter((x)=>x.categoria == 'Video')" style="margin-bottom: 4px;">
                          <button class="download-button" @click="descargarArchivoPorID(archivo)">Descargar (id: {{ archivo.id }})</button>
                        </div>
                      </div>
                      <div class="yellowish" v-else>
                        No tiene
                      </div>
                    </td>
                    <td>
                      <div v-if="getComprobantePago(solicitud) === 'Descargar'">
                        <div v-for="archivo in solicitud.orden.archivos.filter((x)=>x.categoria == 'Comprobante de Pago')" style="margin-bottom: 4px;">
                          <button class="download-button" @click="descargarArchivoPorID(archivo)">Descargar (id: {{ archivo.id }})</button>
                        </div>
                      </div>
                      <div class="yellowish" v-else>
                        No tiene
                      </div>
                    </td>

                    <td>
                      <div v-if="getFacturaCliente(solicitud) === 'Descargar'">
                        <div v-for="archivo in solicitud.orden.archivos.filter((x)=>x.categoria == 'Factura al Cliente')" style="margin-bottom: 4px;">
                          <button class="download-button" @click="descargarArchivoPorID(archivo)">Descargar (id: {{ archivo.id }})</button>
                        </div>
                      </div>
                      <div class="yellowish" v-else>
                        No tiene
                      </div>
                    </td>
                    <td>{{ getMontoTotal(solicitud) }}</td>
                    <td>{{ getMontoPagado(solicitud) }}</td>
                    <td>{{ getMontoPorPagar(solicitud) }}</td>
                    <td v-if="hasMargenCaptacion()">${{Intl.NumberFormat('de-DE').format(getMontoPorCobrarAJobbing(solicitud))}}</td>
                    <td v-if="hasMargenCaptacion()">

                   
                      <div v-if="getFacturaHaciaJobbing(solicitud) === 'Descargar'">
                        <div v-for="archivo in solicitud.orden.archivos.filter((x)=>x.categoria == 'Factura hacia Jobbing')" style="margin-bottom: 4px;">
                          <button class="download-button" @click="descargarArchivoPorID(archivo)">Descargar (id: {{ archivo.id }})</button>
                        </div>
                      </div>
                      <div class="yellowish" v-else>
                        No tiene
                      </div>
                   
                    </td>
                    <td>{{ getFechaCalificacion(solicitud) }}</td>
                    <td>
                      <div style="white-space: nowrap;" v-if="solicitud.orden && solicitud.orden.calificacion && solicitud.orden.calificacion.calificacion">
                        <b-icon class="star" icon="star-fill" v-for="_ in solicitud.orden.calificacion.calificacion"></b-icon>

                      </div>
                      <div v-else>
                        Sin caclificación
                      </div>
                    </td>
                  </tr>
                  <!-- Fila para mostrar detalles -->
                  <tr :id="'details' + index" style="display: none; background-color: rgb(250,250,250);">
                    <td colspan="22">
                      <div class="details-container">
                        <!-- Fotos -->
                        <!-- Información de contacto -->
                        <div class="sectionv">
                          <h2>Información de contacto 📋</h2>
                          <table style="min-width: 400px;">
                            <tr>
                              <td><b>Nombre</b></td>
                              <td>{{ solicitud.nombre }} {{solicitud.apellido}}</td>
                            </tr>
                            <tr>
                              <td><b>Email</b></td>
                              <td>{{ solicitud.email }} </td>
                            </tr>
                            <tr>
                              <td><b>Teléfono</b></td>
                              <td>{{ solicitud.telefono }}</td>
                            </tr>
                          </table>
                        </div>

                        <!-- Información de la Orden -->
                        <div class="sectionv" v-if="solicitud.orden">
                          <h2>Información de la orden 📦</h2>
                          <div v-if="solicitud.orden.servicios && solicitud.orden.servicios.length">

                          <h3>Servicios agregados:</h3>
                          <table style="min-width: 400px;">

                              <tr>
                                <th>Nombre</th>
                                <th>Precio</th>
                              </tr>
                
                            <tr v-for="s in solicitud.orden.servicios">
                              <td> {{ s.nombre }}</td>
                              <td> ${{Intl.NumberFormat('de-DE').format( s.precio )}}</td>
                            </tr>
                          </table>
                          <br />
                        </div>
                          <div v-if="solicitud.orden.pagos && solicitud.orden.pagos.length">
                            <h3>Pagos</h3>
                 
                            <table style="min-width: 400px;">   
                              <tr>
                                <th>Id</th>
                                <th>Monto</th>
                                <th>Estado</th>
                                <th>Fecha</th>
                                <th>Método</th>
                                <th>Recibido por</th>
                        
                              </tr>

                            <tbody>
                              <tr v-for="pago in solicitud.orden.pagos" :key="pago.id">
                                <td>{{ pago.id }}</td>
                                <td>${{ Intl.NumberFormat('de-DE').format(pago.monto) }}</td>
                                <td>
                                  <div  style="color: #2ECC40;" v-if="pago.estado == 1">
                                    Pagado
                                  </div>
                                  <div style="color: #FF4136;" v-else-if="pago.estado == 2">
                                    Devuelto
                                  </div>
                                  <div style="color: #AAAAAA;" v-if="pago.estado == 0">
                                    No pagado
                                  </div>
                                </td>
                                <td>{{ new Date(pago.fecha_pagado.y, pago.fecha_pagado.m, pago.fecha_pagado.d).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}</td>
                                <td>{{ pago.metodo_pago }}</td>
                                <td>{{ pago.recibido_por == 'jobbing' ? 'Jobbing' : admin_datos.empresa.nombre }}</td>
                              </tr>
                            </tbody>
                            </table>
                            <br/>
                          </div>  
                          <!-- Visitas -->
                          
                          <div v-if="solicitud.orden.visitas && solicitud.orden.visitas.length">
                            <h3>Visitas</h3>
                            <table style="min-width: 400px;">
   
                                <tr>
                                  <th>Estado</th>
                                  <th>Fecha</th>
                                  <th>Hora</th>
                                  <th>Servicios Realizados</th>
                                </tr>
           
                              <tbody>
                                <tr v-for="visita in solicitud.orden.visitas" :key="visita.id">
                                  
                                  <td :style="{'color':visita.estado == 10 ? '#FF4136': visita.estado == 4 ? '#2ECC40' : ''}">{{ getEstadoVisita(visita) }}</td>
                                  <td>{{ extractDate(visita.fecha_).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) }}</td>
                                  <td>{{ timeConvert(visita.desde) }} hrs</td>
                                  <td>
                                    <div v-if="solicitud.orden && solicitud.orden.servicios && solicitud.orden.servicios.some(x=>x.visita_id == visita.id)">
                                      <div v-for="servicio in  solicitud.orden.servicios.filter(x=>x.visita_id == visita.id)">
                                        <b>{{ servicio.nombre }}</b>, por ${{ Intl.NumberFormat('de-DE').format(servicio.precio) }}
                                      </div>
                                    </div>
                                    <div v-else>
                                      No se especifica

                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br/>
                          </div>
                          

                          <!-- Presupuestos -->
                          <div v-if="solicitud.orden.presupuestos && solicitud.orden.presupuestos.length">
                            <h3>Presupuestos</h3>
                            <table>
         
                                <tr>
                                  <th>Presupuesto ID</th>
                                  <th>Monto</th>
                                  <th>Descripción</th>
                                  <th>Fecha Creado</th>
                                  <th>Fecha Aprobado</th>
                                  <th>Acciones</th>
                                </tr>
     
                              <tbody>
                                <tr v-for="presupuesto in solicitud.orden.presupuestos" :key="presupuesto.id">
                                
                                  <td>{{ presupuesto.id }}</td>
                                  <td>${{ Intl.NumberFormat('de-DE').format(presupuesto.monto_total) }}</td>
                                  <td>{{ presupuesto.descripcion }}</td>
                                  <td>{{ extractDate(presupuesto.fecha_creado_iso) ? extractDate(presupuesto.fecha_creado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) : 'No especifica' }}</td>
                                  <td>
                                    <div style="color: #2ECC40; font-weight: 700;" v-if="presupuesto.fecha_aprobado_iso">
                                      {{ extractDate(presupuesto.fecha_aprobado_iso) ? extractDate(presupuesto.fecha_aprobado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) : null || 'No aprobado' }}
                                      
                                    </div>
                                    <div v-else>
                                        No aprobado
                                    </div>
                                  </td>
                                  <td>
                                    <button v-on:click="descargarPresupuesto(presupuesto)" style="margin: 0px 2px" class="download-button"> Descargar <b-icon icon="download"></b-icon> </button>
                                    <button v-if="!presupuesto.fecha_aprobado_iso" v-on:click="reenviarPresupuesto(presupuesto)" style="margin: 0px 2px" class="download-button"> Reenviar <b-icon icon="arrow-counterclockwise"></b-icon> </button>
                                    <button v-if="!presupuesto.fecha_aprobado_iso" v-on:click="copiarLinkPresupuesto(presupuesto)" style="margin: 0px 2px" class="download-button"> Copiar link <b-icon icon="clipboard"></b-icon> </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="sectionv">
                          <h2>Fotos 📸</h2>
                          <div v-if="hasFotos(solicitud)">                            
                            <h3>Antes</h3>
                            <div class="fotos-container">
                              <div class="image-wrapper" v-for="foto in getFotosAntes(solicitud)" :key="foto.id">
                                <img :src="obtenerURLFoto(foto)" alt="Foto Antes" />
                                <div class="hover-message">{{ extractDate(foto.fecha_creado).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })}}</div>
                              </div>
                            </div>
                            
                            <h3>Después</h3>
                            <div class="fotos-container">
                              <div class="image-wrapper" v-for="foto in getFotosDespues(solicitud)" :key="foto.id">
                                <img :src="obtenerURLFoto(foto)" alt="Foto Después" />
                                <div class="hover-message">{{ extractDate(foto.fecha_creado).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })}}</div>
                              </div>
                            </div>
                          </div>
                          <p v-else style="color: red;">No hay fotos</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <!--  {{solicitudes}} -->
          </div>
        </div>
        <div v-else class="articulo-principal">
          Cargando...
        </div>
      </div>
    </div>
    <!-- Context Menu -->
    <div
      v-if="contextMenuVisible"
      :style="{ top: contextMenuY + 'px', left: contextMenuX + 'px' }"
      class="context-menu"
    >
      <ul>
        <li @click="abrirModalEditarSolicitud(selectedSolicitud)">Editar Solicitud</li>
        <li @click="abrirModalAgregarComentario(selectedSolicitud)">Agregar Comentario</li>
        <li @click="abrirModalSubirArchivo(selectedSolicitud, 'Comprobante de Pago')">Agregar Comprobante de Pago</li>
        <li @click="abrirModalSubirArchivo(selectedSolicitud, 'Factura al Cliente')">Agregar Factura al Cliente</li>
        <li @click="abrirModalAgregarPago(selectedSolicitud)">Agregar Pago</li>
        <li v-if="selectedSolicitud && selectedSolicitud.orden" @click="abrirModalEnviarPresupuesto(selectedSolicitud)">Enviar presupuesto</li>
        <li style="color: #FF4136;" @click="marcarSolicitudInactiva(selectedSolicitud.id)">Marcar como inactiva</li>
        <!-- Add more options here -->
      </ul>
    </div>

    <!-- Modal for Adding Comment -->
    <div v-if="modalVisible" class="modal-overlay" @click.self="cerrarModal">
      <div class="modall">
        <h3>Agregar Comentario</h3>
        <textarea v-model="nuevoComentario" placeholder="Escribe tu comentario aquí"></textarea>
        <div class="modal-buttons">
          <button @click="agregarComentario">Agregar</button>
          <button @click="cerrarModal">Cancelar</button>
        </div>
      </div>
    </div>

    <!-- Modal for Editing Solicitud -->
    <div v-if="modalEditarSolicitudVisible" class="modal-overlay" @click.self="cerrarModalEditarSolicitud">
  <div class="modall">
    <h3>Editar Solicitud</h3>
    <table class="modal-inputs">
      <tr>
        <td><label>Título:</label></td>
        <td><input type="text" v-model="editedSolicitud.titulo" /></td>
      </tr>
      <!-- New Descripción Field -->
      <tr>
        <td><label>Descripción:</label></td>
        <td><textarea v-model="editedSolicitud.descripcion"></textarea></td>
      </tr>
      <tr>
        <td><label>Nombre:</label></td>
        <td><input type="text" v-model="editedSolicitud.nombre" /></td>
      </tr>
      <tr>
        <td><label>Apellido:</label></td>
        <td><input type="text" v-model="editedSolicitud.apellido" /></td>
      </tr>
      <tr>
        <td><label>Email:</label></td>
        <td><input type="email" v-model="editedSolicitud.email" /></td>
      </tr>
      <tr>
        <td><label>Teléfono:</label></td>
        <td><input type="text" v-model="editedSolicitud.telefono" /></td>
      </tr>
      <tr>
        <td><label>Dirección:</label></td>
        <td><input type="text" v-model="editedSolicitud.direccion" /></td>
      </tr>
      <!-- Change Comuna to a Select Dropdown -->
      <tr>
        <td><label>Comuna:</label></td>
        <td>
          <select v-model="editedSolicitud.comuna_id">
            <option v-for="comuna in comunas" :value="comuna.id">{{ comuna.Nombre }}</option>
          </select>
        </td>
      </tr>
      <!-- Add other fields as needed -->
    </table>
    <div class="modal-buttons">
      <button @click="guardarCambiosSolicitud">Guardar</button>
      <button @click="cerrarModalEditarSolicitud">Cancelar</button>
    </div>
  </div>
</div>

    <!-- Modal for Uploading File -->
    <div v-if="modalSubirArchivoVisible" class="modal-overlay" @click.self="cerrarModalSubirArchivo">
      <div class="modall">
        <h3>Agregar {{ archivoTipo }}</h3>
        <input type="file" @change="onFileSelected">
        <div class="modal-buttons">
          <button @click="subirArchivo">Subir</button>
          <button @click="cerrarModalSubirArchivo">Cancelar</button>
        </div>
      </div>
    </div>

    <!-- Modal for Adding Payment -->
    <div v-if="modalAgregarPagoVisible" class="modal-overlay" @click.self="cerrarModalAgregarPago">
      <div class="modall">
        <h3>Agregar Pago</h3>
        <table class="modal-inputs">
          <tr>
            <td><label>Monto:</label></td>
            <td><input type="number" v-model="nuevoPagoMonto" /></td>
          </tr>
          <tr>
            <td><label>Fecha de Pago:</label></td>
            <td><input type="date" v-model="nuevoPagoFecha" /></td>
          </tr>
          <tr>
            <td><label>Recibido por:</label></td>
            <td>
              <select v-model="nuevoPagoRecibidoPor">
                <option value="jobbing">Jobbing</option>
                <option value="administradora">{{admin_datos.empresa && admin_datos.empresa.nombre ? admin_datos.empresa.nombre : 'Administradora'}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td><label>Comprobante de Pago (opcional):</label></td>
            <td><input type="file" @change="onPagoFileSelected" /></td>
          </tr>
        </table>
        <div class="modal-buttons">
          <button @click="agregarPago">Agregar</button>
          <button @click="cerrarModalAgregarPago">Cancelar</button>
        </div>
      </div>
    </div>

    <!-- Modal for Enviar Presupuesto -->
    <div v-if="modalEnviarPresupuestoVisible" class="modal-overlay" @click.self="cerrarModalEnviarPresupuesto">
      <div class="modall">
        <h3>Enviar Presupuesto</h3>
        <table class="modal-inputs">
          <tr>
            <td><label>Nombre:</label></td>
            <td><input type="text" v-model="presupuestoNombre" /></td>
          </tr>
          <tr>
            <td><label>Notas Adicionales:</label></td>
            <td><textarea v-model="presupuestoNotasAdicionales"></textarea></td>
          </tr>
          <tr>
            <td><label>Items:</label></td>
            <td>
              <div v-if="selectedSolicitud && selectedSolicitud.orden && selectedSolicitud.orden.servicios">
                <div v-for="servicio in selectedSolicitud.orden.servicios" :key="servicio.id">
                  <label>
                    <input type="checkbox" :value="servicio" v-model="presupuestoItems" :checked="true" />
                    {{ servicio.nombre }} - ${{ Intl.NumberFormat('de-DE').format(servicio.precio) }}
                  </label>
                </div>
              </div>
              <div v-else>
                No hay servicios disponibles.
              </div>
            </td>
          </tr>
        </table>
        <div class="modal-buttons">
          <button @click="vistaPreviaPresupuesto">Vista Previa</button>
          <button @click="enviarPresupuesto">Enviar</button>
          <button @click="cerrarModalEnviarPresupuesto">Cancelar</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'SolicitudesCliente',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      solicitudes: [],
      loaded: false,
      searchQuery: '', // Added searchQuery data property
      contextMenuVisible: false,
      contextMenuX: 0,
      contextMenuY: 0,
      selectedSolicitud: null,
      modalVisible: false,
      nuevoComentario: '',
      modalSubirArchivoVisible: false,
      archivoTipo: '',
      archivoSeleccionado: null,
      modalAgregarPagoVisible: false,
      nuevoPagoMonto: null,
      nuevoPagoFecha: null,
      nuevoPagoRecibidoPor: 'jobbing',
      pagoArchivoSeleccionado: null,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      preventClick: false,
      modalEnviarPresupuestoVisible: false,
      presupuestoNombre: '',
      presupuestoNotasAdicionales: '',
      presupuestoItems: [],
      modalEditarSolicitudVisible: false,
      editedSolicitud: {},
      dateFilter: 'last_2_months', // Nuevo: Filtro de fecha por defecto "Hace dos meses"
    };
  },
  computed: {
    ...mapGetters(['admin_datos', 'comunas']),
    filteredSolicitudes() {
      if (!this.searchQuery) {
        return this.solicitudes;
      }
      const query = this.searchQuery.toLowerCase();
      return this.solicitudes.filter(solicitud => {
        const comuna = solicitud.comuna_nombre ? solicitud.comuna_nombre.toLowerCase() : '';
        const titulo = solicitud.titulo ? solicitud.titulo.toLowerCase() : '';
        const estado = solicitud.estado_front && solicitud.estado_front.estado ? solicitud.estado_front.estado.toLowerCase() : '';
        const direccion = solicitud.direccion ? solicitud.direccion.toLowerCase() : '';
        const descripcion = solicitud.descripcion ? solicitud.descripcion.toLowerCase() : '';
        return (
          comuna.includes(query) ||
          titulo.includes(query) ||
          estado.includes(query) ||
          direccion.includes(query) ||
          descripcion.includes(query)
        );
      });
    },
  
  },
  methods: {
      abrirModalEditarSolicitud(solicitud) {
      this.contextMenuVisible = false;
      this.selectedSolicitud = solicitud;
      this.modalEditarSolicitudVisible = true;
      // Create a deep copy to avoid mutating the original object before saving
      this.editedSolicitud = JSON.parse(JSON.stringify(solicitud));
      // Ensure comuna_id is set
      this.editedSolicitud.comuna_id = solicitud.comuna_id;
    },

    cerrarModalEditarSolicitud() {
      this.modalEditarSolicitudVisible = false;
      this.editedSolicitud = {};
    },

    guardarCambiosSolicitud() {
    // Validate inputs if necessary
      axios
        .post(window.hostname + 'api/property_manager/editar_solicitud', {
          ...this.editedSolicitud,
          comuna_id: this.editedSolicitud.comuna_id, // Ensure comuna_id is sent
        })
        .then(() => {
          alert('Solicitud actualizada exitosamente.');
          this.cerrarModalEditarSolicitud();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al actualizar solicitud:', e);
          alert('Hubo un error al actualizar la solicitud.');
        });
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    getMontoPorCobrarAJobbing(solicitud){
      if (!solicitud || !solicitud.orden || !solicitud.orden.pagos || !this.admin_datos || !this.admin_datos.empresa) {
      return 0; // Return 0 if any required data is missing
    }

    // Get the margen_captacion value
    const margenCaptacion = this.admin_datos.empresa.margen_captacion;

    // Calculate the total sum
    const total = solicitud.orden.pagos
      .filter(pago => pago.estado === 1 && pago.recibido_por == 'jobbing' && pago.transferencia_empresa_administracion_id === null)
      .reduce((sum, pago) => sum + (pago.monto * margenCaptacion), 0);

    return total;
    },
    onPagoFileSelected(event) {
      this.pagoArchivoSeleccionado = event.target.files[0];
    },
    hasMargenCaptacion() {
      return this.admin_datos.empresa && this.admin_datos.empresa.margen_captacion != 0
    },
    extractDate(isoString) {
      try{
        const datePart = isoString.split("T")[0];
        return new Date(datePart);
      }
      catch(e){
        return null;
      }

    },
    getFechaCalificacion(solicitud){
      if(solicitud.orden && solicitud.orden.calificacion && solicitud.orden.calificacion.fecha_creado){
        const fecha_creado = solicitud.orden.calificacion.fecha_creado
        return new Date(fecha_creado.a, fecha_creado.m, fecha_creado.d).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })
      }
      return 'Sin caclificación'
    }, 
    getEstadoVisita(visita) {
      const estados = {
        0: 'Pendiente',
        1: 'Agendada',
        2: 'Confirmada',
        3: 'En proceso',
        4: 'Terminada',
        10: 'Anulada por retraso'
      }
      return estados[visita.estado];
    },
    fetchSolicitudes(load = true) {
      if (load)
        this.loaded = false;

      let params = {};

      const today = new Date();
      let startDate = null;

      switch (this.dateFilter) {
        case 'today':
          startDate = new Date();
          break;
        case 'last_7_days':
          startDate = new Date();
          startDate.setDate(today.getDate() - 7);
          break;
        case 'last_30_days':
          startDate = new Date();
          startDate.setDate(today.getDate() - 30);
          break;
        case 'last_2_months':
          startDate = new Date();
          startDate.setMonth(today.getMonth() - 2);
          break;
        case 'last_6_months':
          startDate = new Date();
          startDate.setMonth(today.getMonth() - 6);
          break;
        case 'last_12_months':
          startDate = new Date();
          startDate.setMonth(today.getMonth() - 12);
          break;
        case 'since_beginning':
          // No establecer startDate para obtener todas las solicitudes desde el inicio
          break;
        case 'all':
        default:
          // No establecer startDate, obtener todas las solicitudes hasta la fecha actual
          break;
      }

      if (startDate && this.dateFilter !== 'since_beginning') {
        params.start_date = startDate.toISOString().split('T')[0];
      }
      params.end_date = today.toISOString().split('T')[0];

      axios
        .get(window.hostname + 'api/property_manager/get_solicitudes', { params })
        .then((response) => {
          this.solicitudes = response.data.solicitudes.reverse();
          this.loaded = true;
        })
        .catch((e) => {
          console.log('Error al cargar solicitudes:', e);
          this.loaded = true;
        });
    },
    toggleDetails(id, event) {
      if (this.preventClick) {
        this.preventClick = false;
        return;
      }

      const ignoredClasses = ['download-button'];
      if (ignoredClasses.some(className => event.target.classList.contains(className))) {
        return;
      }

      const detailsRow = document.getElementById(id);
      if (detailsRow.style.display === 'table-row') {
        detailsRow.style.display = 'none';
      } else {
        detailsRow.style.display = 'table-row';
      }
    },
    obtenerURLFoto(foto) {
      return foto['link'];
    },
    getAtendidoPor(solicitud) {
      if (solicitud.orden && solicitud.orden.profesional) {
        return solicitud.orden.profesional.nombre+ ' '+solicitud.orden.profesional.apellido
      }
      return 'Aun no asignada';
    },
    getFechaVisita(solicitud) {
      if (solicitud.orden && solicitud.orden.visitas && solicitud.orden.visitas.filter(visita => ![10, 0].includes(visita.estado)).length > 0) {
        return this.extractDate(solicitud.orden.visitas[0].fecha_).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
      }
      return 'No hay visita';
    },
    getFechaEnvioPresupuesto(solicitud) {
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        return this.extractDate(solicitud.orden.presupuestos[0].fecha_creado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
      }
      return 'No se ha enviado';
    },
    getMontoTotal(solicitud) {
      if (solicitud.orden && solicitud.orden.servicios && solicitud.orden.servicios.length > 0) {
        return '$'+Intl.NumberFormat('de-DE').format(solicitud.orden.servicios.reduce((total, item) => {
          return total + (item.precio || 0);
        }, 0));
      }
      return 'No hay servicios agregados';
    },
    getMontoPagado(solicitud) {
      if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
        const totalPagado = solicitud.orden.pagos
          .filter(item => item.estado === 1) // Filter pagos with estado == 1
          .reduce((total, item) => total + (item.monto || 0), 0);
          
        return totalPagado > 0 ? '$' + Intl.NumberFormat('de-DE').format(totalPagado) : 'No hay pagos hechos';
      }
      return 'No hay pagos hechos';
    },
    getMontoPagadoNumero(solicitud) {
      if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
        const totalPagado = solicitud.orden.pagos
          .filter(item => item.estado === 1) // Filter pagos with estado == 1
          .reduce((total, item) => total + (item.monto || 0), 0);
          
        return totalPagado > 0 ? totalPagado : 0;
      }
      return 0;
    },
    getMontoPorPagar(solicitud) {
      let total_orden = 0;
      if (solicitud.orden && solicitud.orden.servicios && solicitud.orden.servicios.length > 0) {
        total_orden = solicitud.orden.servicios.reduce((total, item) => {
          return total + (item.precio || 0); 
        }, 0);      
      }

      let total_pagado = 0;
      if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
        total_pagado = solicitud.orden.pagos.reduce((total, item) => {
          return total + (item.monto || 0); 
        }, 0)
      }
      return '$'+Intl.NumberFormat('de-DE').format(total_orden - total_pagado)
    },
    getFechaAprobacion(solicitud) {
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        const presupuesto = solicitud.orden.presupuestos[0];
        if (presupuesto.fecha_aprobado_iso){
          return  '<span style="color:#2ECC40;">'+this.extractDate(presupuesto.fecha_aprobado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })+'</span>'
        }
        return presupuesto.fecha_aprobado_iso || '<span style="color:#FFDC00;">No aprobado</span>';

      }
      return '<span style="color:#FF4136;">No enviado</span>';
    },
    getFechaInicio(solicitud) {
      if (solicitud.orden && solicitud.orden.visitas && solicitud.orden.visitas.filter(visita => ![10, 0].includes(visita.estado)).length > 1) {
        return this.extractDate(solicitud.orden.visitas[1].fecha_).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
      }
      return '<span style="white-space:nowrap;">No hay fecha</span> de inicio';
    },
    getComentarios(solicitud) {
      if (
        solicitud.atributos &&
        solicitud.atributos.comentarios &&
        solicitud.atributos.comentarios.length > 0
      ) {
        return solicitud.atributos.comentarios
          .map(
            (c) =>
              `<div> ${this.formatFecha(c.fecha)} <b>${c.autor_nombre}</b>: ${c.comentario} </div>`
          )
          .join(' <br/> ');
      }
      return 'No hay comentarios';
    },

    formatFecha(fecha) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(fecha).toLocaleDateString('es-ES', options);
    },
    hasFotos(solicitud) {
      return solicitud.orden && solicitud.orden.fotos && solicitud.orden.fotos.length > 0;
    },
    getFotosAntes(solicitud) {
      if (this.hasFotos(solicitud)) {
        return solicitud.orden.fotos.filter((foto) => foto.tipo === 1);
      }
      return [];
    },
    getFotosDespues(solicitud) {
      if (this.hasFotos(solicitud)) {
        return solicitud.orden.fotos.filter((foto) => foto.tipo === 2);
      }
      return [];
    },
    getComprobantePago(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Comprobante de Pago')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    getFacturaHaciaJobbing(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Factura hacia Jobbing')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    getFacturaCliente(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Factura al Cliente')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    getVideo(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Video')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    descargarArchivo(solicitud, categoria) {
      if(solicitud.orden && solicitud.orden.archivos) 
      {     
        const archivo = solicitud.orden.archivos.find(
          (archivo) => archivo.categoria === categoria
        );
        if (archivo) {
          const url = `${window.hostname}api/property_manager/descargar_archivo/${archivo.id}`;
          window.open(url, '_blank');
        }
      }
      },
      descargarArchivoPorID(archivo) {
        if (archivo) {
          const url = `${window.hostname}api/property_manager/descargar_archivo/${archivo.id}`;
          axios.get(url)
        .then(response => {
          const presignedUrl = response.data.url;
          window.open(presignedUrl, '_blank');
        })
        .catch(error => {
          console.error('Error al obtener la URL de descarga:', error);
          alert('No se pudo descargar el archivo. Por favor, inténtalo de nuevo.');
        });
        }
      
    },
    // New Methods for Context Menu and Modal
    openContextMenu(event, solicitud) {
      this.contextMenuVisible = true;
      this.contextMenuX = event.clientX;
      this.contextMenuY = event.clientY;
      this.selectedSolicitud = solicitud;
    },
    abrirModalAgregarComentario(solicitud) {
      this.contextMenuVisible = false;
      this.modalVisible = true;
      this.nuevoComentario = '';
    },
    cerrarModal() {
      this.modalVisible = false;
    },
    agregarComentario() {
      if (this.nuevoComentario.trim() === '') {
        alert('El comentario no puede estar vacío.');
        return;
      }
      // Assuming there's an API endpoint to add a comment to a solicitud
      axios
        .post(window.hostname + 'api/property_manager/agregar_comentario', {
          solicitud_id: this.selectedSolicitud.id,
          comentario: this.nuevoComentario,
        })
        .then(() => {
          alert('Comentario agregado exitosamente.');
          this.cerrarModal();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al agregar comentario:', e);
          alert('Hubo un error al agregar el comentario.');
        });
    },
    abrirModalSubirArchivo(solicitud, tipo) {
      this.contextMenuVisible = false;
      this.selectedSolicitud = solicitud;
      this.archivoTipo = tipo;
      this.archivoSeleccionado = null;
      this.modalSubirArchivoVisible = true;
    },
    cerrarModalSubirArchivo() {
      this.modalSubirArchivoVisible = false;
      this.archivoSeleccionado = null;
    },
    onFileSelected(event) {
      this.archivoSeleccionado = event.target.files[0];
    },
    subirArchivo() {
      if (!this.archivoSeleccionado) {
        alert('Por favor, selecciona un archivo.');
        return;
      }

      const formData = new FormData();

      // Create the JSON document
      const jsonDocument = {
        nombre: this.archivoSeleccionado.name,
        solicitud_id: this.selectedSolicitud.id,
        categoria: this.archivoTipo,
      };

      // Convert JSON document to a Blob
      const jsonBlob = new Blob([JSON.stringify(jsonDocument)], { type: 'application/json' });

      // Append 'document' and 'file' to FormData
      formData.append('document', jsonBlob, 'document.json');
      formData.append('file', this.archivoSeleccionado);

      axios
        .post(window.hostname + 'api/property_manager/subir_archivo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          alert('Archivo subido exitosamente.');
          this.cerrarModalSubirArchivo();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al subir archivo:', e);
          alert('Hubo un error al subir el archivo.');
        });
    },
        abrirModalAgregarPago(solicitud) {
          this.contextMenuVisible = false;
          this.selectedSolicitud = solicitud;
          this.modalAgregarPagoVisible = true;
          this.nuevoPagoMonto = null;
          this.nuevoPagoFecha = null;
          this.nuevoPagoDescripcion = '';
        },


      async marcarSolicitudInactiva(solicitudId) {
      // Solicitar confirmación antes de continuar
      if (!confirm("¿Estás seguro de que deseas marcar esta solicitud como inactiva?")) {
        return; // Salir si el usuario cancela
      }

      try {
        // Enviar la solicitud POST con solicitud_id en el cuerpo de la solicitud
        const response = await axios.post(window.hostname + '/api/property_manager/marcar_solicitud_inactiva', {
          solicitud_id: solicitudId
        });

        // Manejar respuesta exitosa
        if (response.status === 200) {
          this.fetchSolicitudes(false); 
          alert("La solicitud ha sido marcada como inactiva.");
        }
      } catch (error) {
        // Manejar errores
        if (error.response && error.response.status === 401) {
          alert("No estás autorizado para marcar esta solicitud como inactiva.");
        } else if (error.response && error.response.status === 400) {
          alert("Solicitud inválida. Por favor verifica el ID de la solicitud.");
        } else {
          alert("Ocurrió un error al procesar tu solicitud.");
        }
      }
    },
    cerrarModalAgregarPago() {
      this.modalAgregarPagoVisible = false;
    },
    agregarPago() {
      // Validate the input
      if (!this.nuevoPagoMonto || !this.nuevoPagoFecha || !this.nuevoPagoRecibidoPor) {
        alert('Por favor, completa todos los campos requeridos.');
        return;
      }

      const formData = new FormData();

      // Create the JSON document
      const pagoData = {
        solicitud_id: this.selectedSolicitud.id,
        monto: this.nuevoPagoMonto,
        fecha_pago: this.nuevoPagoFecha,
        recibido_por: this.nuevoPagoRecibidoPor,
      };

      // Convert JSON document to a Blob
      const jsonBlob = new Blob([JSON.stringify(pagoData)], { type: 'application/json' });

      // Append 'document' and optional 'comprobante_pago' to FormData
      formData.append('document', jsonBlob, 'document.json');

      if (this.pagoArchivoSeleccionado) {
        formData.append('comprobante_pago', this.pagoArchivoSeleccionado);
      }

      // Send the data to the server
      axios
        .post(window.hostname + 'api/property_manager/agregar_pago', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          alert('Pago agregado exitosamente.');
          this.cerrarModalAgregarPago();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al agregar pago:', e);
          alert('Hubo un error al agregar el pago.');
        });
    },
    abrirModalEnviarPresupuesto(solicitud) {
      this.contextMenuVisible = false;
      this.selectedSolicitud = solicitud;

      if (!solicitud.orden) {
        alert('No se puede enviar presupuesto porque la solicitud no tiene una orden asociada.');
        return;
      }

      if (solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        // Ask the user if they really want to send that "presupuesto".
        if (confirm('Ya existe un presupuesto. ¿Deseas enviar uno nuevo?')) {
          this.preparePresupuestoModal();
        } 
      } else {
        // No existing presupuesto, proceed to create a new one
        this.preparePresupuestoModal();
      }
    },
    preparePresupuestoModal() {
      this.modalEnviarPresupuestoVisible = true;
      this.presupuestoNombre = '';
      this.presupuestoNotasAdicionales = '';
      this.presupuestoItems = [];
      if (this.selectedSolicitud && this.selectedSolicitud.orden && this.selectedSolicitud.orden.servicios) {
        this.presupuestoItems = [...this.selectedSolicitud.orden.servicios];
      }
    },
    cerrarModalEnviarPresupuesto() {
      this.modalEnviarPresupuestoVisible = false;
      this.presupuestoNombre = '';
      this.presupuestoNotasAdicionales = '';
      this.presupuestoItems = [];
    },
    async vistaPreviaPresupuesto ()  {
      if (!this.presupuestoNombre.trim()) {
        alert('Por favor, ingrese un nombre para el presupuesto.');
        return;
      }
      if (this.presupuestoItems.length === 0) {
        alert('Por favor, seleccione al menos un item.');
        return;
      }

      const presupuestoData = {
        orden_id: this.selectedSolicitud.orden.id,
        nombre: this.presupuestoNombre,
        notas_adicionales: this.presupuestoNotasAdicionales,
        items: this.presupuestoItems,
      };
      try {
        const response = await axios.post(window.hostname+'api/property_manager/vista_previa_presupuesto', presupuestoData, {
          responseType: 'blob'
        });



        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cotizacion.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the PDF', error);
      }
    },
    async descargarPresupuesto (presupuesto)  {


      const presupuestoData = {
        orden_id: presupuesto.orden_id,
        nombre: presupuesto.nombre,
        notas_adicionales: presupuesto.desgloce.notas_adicionales,
        items: presupuesto.desgloce.items,
      };
      try {
        const response = await axios.post(window.hostname+'api/property_manager/vista_previa_presupuesto', presupuestoData, {
          responseType: 'blob'
        });



        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cotizacion.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the PDF', error);
      }
    },
    enviarPresupuesto() {
      if (!this.presupuestoNombre.trim()) {
        alert('Por favor, ingrese un nombre para el presupuesto.');
        return;
      }
      if (this.presupuestoItems.length === 0) {
        alert('Por favor, seleccione al menos un item.');
        return;
      }

      const presupuestoData = {
        orden_id: this.selectedSolicitud.orden.id,
        nombre: this.presupuestoNombre,
        notas_adicionales: this.presupuestoNotasAdicionales,
        items: this.presupuestoItems,
      };
      

      axios.post(window.hostname + 'api/property_manager/crear_y_enviar_presupuesto', presupuestoData)
        .then(() => {
          alert('Presupuesto enviado exitosamente.');
          this.cerrarModalEnviarPresupuesto();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al enviar presupuesto:', e);
          alert('Hubo un error al enviar el presupuesto.');
        });
    },
    copiarLinkPresupuesto(presupuesto) {
    axios.post(window.hostname + 'api/property_manager/obtener_link_presupuesto', { presupuesto_id: presupuesto.id })
      .then((response) => {
        const link = response.data.link; // Assuming 'link' is returned in response
        navigator.clipboard.writeText(link)
          .then(() => {
            alert('Link copiado.');
          })
          .catch((err) => {
            console.log('Error copiando link:', err);
            alert('Error copiando link.');
          });
      })
      .catch((error) => {
        console.log('Error fetching link:', error);
        alert('There was an error fetching the link.');
      });
  },
    reenviarPresupuesto(presupuesto) {
      axios.post(window.hostname + 'api/property_manager/reenviar_presupuesto', {presupuesto_id:presupuesto.id})
        .then(() => {
          alert('Presupuesto enviado exitosamente.');
          this.cerrarModalEnviarPresupuesto();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al enviar presupuesto:', e);
          alert('Hubo un error al enviar el presupuesto.');
        });
    },
    // Event handler for closing the context menu
    handleWindowClick(event) {
      const ignoredClasses = ['context-menu'];
      if (!ignoredClasses.some(className => event.target.classList.contains(className))) {
        this.contextMenuVisible = false;
      }

      if (!this.$el.contains(event.target)) {
        this.contextMenuVisible = false;
      }
    },
    dragStart(event) {
      this.isDragging = true;
      this.preventClick = false;
      this.$refs.tableContainer.classList.add('active');
      if (event.type === 'touchstart') {
        this.startX = event.touches[0].pageX - this.$refs.tableContainer.offsetLeft;
      } else {
        this.startX = event.pageX - this.$refs.tableContainer.offsetLeft;
      }
      this.scrollLeft = this.$refs.tableContainer.scrollLeft;
    },
    dragMove(event) {
      if (!this.isDragging) return;
      event.preventDefault();
      let x;
      if (event.type === 'touchmove') {
        x = event.touches[0].pageX - this.$refs.tableContainer.offsetLeft;
      } else {
        x = event.pageX - this.$refs.tableContainer.offsetLeft;
      }
      const deltaX = x - this.startX;
      if (Math.abs(deltaX) > 5) {
        this.preventClick = true;
      }
      const walk = deltaX * 1;
      this.$refs.tableContainer.scrollLeft = this.scrollLeft - walk;
    },
    dragEnd() {
      this.isDragging = false;
      this.$refs.tableContainer.classList.remove('active');
    },
  },
  created() {
    if (!this.admin_datos || this.admin_datos.user_type !== 'property_manager') {
      this.$router.push('/');
    } else {
      this.fetchSolicitudes();
    }
    window.addEventListener('click', this.handleWindowClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleWindowClick);
  },
};
</script>

<style scoped>
/* Estilo para el contenedor de encabezado */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Estilo para el contenedor del filtro de fecha */
.date-filter-container {
  display: flex;
  align-items: center;
  justify-content: center;

}

/* Estilo para la etiqueta del filtro de fecha */
.date-filter-label {
  margin-right: 5px;
  font-weight: bold;
  margin-top: 8px;
}

/* Estilo para el selector de fecha */
.date-filter-select {
  padding: 5px;
  font-size: 14px;
}


.star{
  cursor: pointer;
  margin-right:4px;
  height:18px;
  width: 18px;
  color:#DAA520;
}

.sectionv{
  /* background-color: rgb(250,250,250); */
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

h2{
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}
h3{
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.yellowish {
 color:#FFDC00;
 font-weight: 700;
}
.table-container.active {
  cursor: grabbing;
}

.table-container {
  cursor: grab;
  overflow-x: auto;
  /* user-select: none; */
}

.tablex {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.tablex th,
.tablex td {
  padding: 12px 15px;
  text-align: left;
}

.tablex thead th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.tablex tbody tr {
  border-bottom: 1px solid #ddd;
}

.tablex tbody tr:hover {
  /* background-color: #f9f9f9; */
}

.details-container {
  padding: 15px;
  /* background-color: #f9f9f9; */
}

.details-container h3 {
  margin-top: 0;
}

.fotos-container {
  display: flex;
  flex-wrap: wrap;
}

.fotos-container img {
  width: 150px;
  height: auto;
  margin: 5px;
  border: 1px solid #ccc;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
/* Context Menu Styles */
.context-menu {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  font-size: 14px;
  width: 200px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 5px 0;
}

.context-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu ul li:hover {
  background-color: #f4f4f4;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1100; /* Increased z-index to ensure it's above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modall {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-width: 90%;
  border-radius: 5px;
  z-index: 11001;
}

.modall h3 {
  margin-top: 0;
}

.modall textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

.modal-buttons {
  margin-top: 15px;
  text-align: right;
}

.modal-buttons button {
  padding: 8px 12px;
  margin-left: 10px;
}

/* Ensure context menu is above other elements */
.table-container {
  position: relative;
}
.download-button {
  background-color: #007bff; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 16px; /* Button padding */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer on hover */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline for link buttons */
  display: inline-block;
  white-space: nowrap;
}

.download-button:hover {
  background-color: #3589e4; /* Darker green on hover */
}

.fotos-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.image-wrapper img {
  width: 150px;
  height: auto;
  margin: 5px;
  border: 1px solid #ccc;
}

.hover-message {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 14px;
}

.image-wrapper:hover .hover-message {
  opacity: 1;
}
</style>
