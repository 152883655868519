import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Notifications from 'vue-notification'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  console.error('Vue error:', err);
  console.log('Component Info:', info);
  // Optionally, you can log to an external service here
};

/* window.hostname = 'http://localhost:8000/'; */
window.hostname = 'https://backend1.jobbing.cl/';
//window.hostname = 'http://192.168.50.142:8000/';

//window.socket = 'https://socket.jobbing.cl/';
window.socket = 'https://socket1.jobbing.cl/';
//window.socket = 'https://socket1.veterhome.cl/';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
