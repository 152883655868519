<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">
        <div class="articulo-principal">
          <h2>Gestión de Empresas de Administración de Propiedades</h2>
          <button @click="showAddForm = true" class="btn-add">Agregar Empresa de Administración</button>

          <!-- Formulario para agregar nueva empresa -->
          <div v-if="showAddForm" class="form-container">
            <h3>Agregar Nueva Empresa de Administración</h3>
            <form @submit.prevent="addEmpresaAdministracion">
              <div class="form-group">
                <label>Nombre:</label>
                <input v-model="newEmpresa.nombre" type="text" required>
              </div>
              <div class="form-group">
                <label>Dirección:</label>
                <input v-model="newEmpresa.direccion" type="text">
              </div>
              <div class="form-group">
                <label>Teléfono:</label>
                <input v-model="newEmpresa.telefono" type="text">
              </div>
              <div class="form-group">
                <label>Email:</label>
                <input v-model="newEmpresa.email" type="email">
              </div>
              <div class="form-group">
                <label>Sitio Web:</label>
                <input v-model="newEmpresa.website" type="text">
              </div>
              <button type="submit" class="btn-save">Guardar</button>
              <button @click="showAddForm = false" class="btn-cancel">Cancelar</button>
            </form>
          </div>

          <!-- Tabla de empresas de administración -->
          <table class="tablex" v-if="loaded">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Teléfono</th>
                <th>Email</th>
                <th>Sitio Web</th>
                <th>Margen Captación</th>
                <th>Atributos</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(empresa, index) in empresas" :key="empresa.id">
                <td>{{ empresa.id }}</td>
                <td>
                  <span v-if="!editing[index]">{{ empresa.nombre }}</span>
                  <input v-else v-model="empresa.nombre">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ empresa.direccion }}</span>
                  <input v-else v-model="empresa.direccion">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ empresa.telefono }}</span>
                  <input v-else v-model="empresa.telefono">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ empresa.email }}</span>
                  <input v-else v-model="empresa.email">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ empresa.website }}</span>
                  <input v-else v-model="empresa.website">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ empresa.margen_captacion }}</span>
                  <input v-else v-model="empresa.margen_captacion">
                </td>
                <td>
        <pre v-if="!editing[index]">{{ empresa.atributosString }}</pre>
        <textarea v-else v-model="empresa.atributosString" rows="5"></textarea>
      </td>
                <td>
                  <button v-if="!editing[index]" @click="startEditing(index)">Editar</button>
                  <button v-if="editing[index]" @click="saveChanges(empresa, index)">Guardar</button>
                  <button v-if="editing[index]" @click="cancelEditing(index)">Cancelar</button>
                  <button @click="deleteEmpresaAdministracion(empresa.id)">Eliminar</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="articulo-principal">
            Cargando...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'EmpresaAdministracionManagement',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      empresas: [],
      editing: [], // Seguimiento de las filas que están siendo editadas
      showAddForm: false,
      newEmpresa: {
        nombre: '',
        direccion: '',
        telefono: '',
        email: '',
        website: '',
      },
    };
  },
  created() {
    this.fetchEmpresas();
  },
  methods: {
    fetchEmpresas() {
  this.loaded = false;
  axios
    .get(window.hostname + 'api/superadmin/get_empresas_administracion')
    .then((response) => {
      this.loaded = true;
      this.empresas = response.data.empresas.map((empresa) => {
        return {
          ...empresa,
          atributosString: JSON.stringify(empresa.atributos, null, 2),  // Convertir JSON a cadena
        };
      });
      this.editing = this.empresas.map(() => false);
    })
    .catch((e) => {
      this.loaded = true;
      console.log('Error al cargar empresas de administración...', e);
    });
},
    startEditing(index) {
      this.$set(this.editing, index, true);
    },
    cancelEditing(index) {
      this.$set(this.editing, index, false);
      this.fetchEmpresas(); // Revertir cambios locales
    },
    saveChanges(empresa, index) {
  let atributos = null;
  if (empresa.atributosString) {
    try {
      atributos = JSON.parse(empresa.atributosString);
    } catch (e) {
      alert('El campo "Atributos" no contiene un JSON válido.');
      return;
    }
  }

  const empresaData = {
    id: empresa.id,
    nombre: empresa.nombre,
    direccion: empresa.direccion,
    telefono: empresa.telefono,
    email: empresa.email,
    website: empresa.website,
    margen_captacion: empresa.margen_captacion,
    atributos: atributos,
  };

  axios
    .post(window.hostname + 'api/superadmin/update_empresa_administracion', empresaData)
    .then(() => {
      alert('Empresa de administración actualizada');
      this.$set(this.editing, index, false);
    })
    .catch((e) => {
      console.log('Error al actualizar empresa de administración', e);
    });
},
    deleteEmpresaAdministracion(empresaId) {
      if (confirm('¿Estás seguro de que deseas eliminar esta empresa de administración?')) {
        axios
          .post(window.hostname + 'api/superadmin/delete_empresa_administracion', { id: empresaId })
          .then(() => {
            alert('Empresa de administración eliminada');
            this.fetchEmpresas();
          })
          .catch((e) => {
            console.log('Error al eliminar empresa de administración', e);
          });
      }
    },
    addEmpresaAdministracion() {
      axios
        .post(window.hostname + 'api/superadmin/add_empresa_administracion', this.newEmpresa)
        .then(() => {
          alert('Empresa de administración agregada');
          this.showAddForm = false;
          this.newEmpresa = {
            nombre: '',
            direccion: '',
            telefono: '',
            email: '',
            website: '',
          };
          this.fetchEmpresas();
        })
        .catch((e) => {
          console.log('Error al agregar empresa de administración', e);
        });
    },
  },
  computed: {
    ...mapGetters(['admin_datos']),
  },
  mounted() {
    if (!this.admin_datos.superadmin) {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
/* Estilos similares a los de tus componentes existentes */

.tablex th,
.tablex td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.tablex th {
  background-color: rgb(38, 53, 67);
  color: white;
}

.tablex {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.btn-add {
  background-color: #28a745;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-add:hover {
  background-color: #218838;
}

.btn-save {
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-save:hover {
  background-color: #0069d9;
}

.btn-cancel {
  background-color: #dc3545;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-cancel:hover {
  background-color: #c82333;
}

.form-container {
  background-color: #f8f9fa;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
</style>
