<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos && admin_datos.user_type === 'jobbing_admin'" class="main-container">
        <div class="articulo-principal">
          <h2>Asignar Profesionales a Empresa de Administración</h2>
          <div v-if="loaded">
            <div class="form-group">
              <label for="empresa-select">Seleccione una Empresa de Administración:</label>
              <select id="empresa-select" v-model="selectedEmpresaId" @change="onEmpresaChange">
                <option disabled value="">Seleccione una empresa</option>
                <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">
                  {{ empresa.nombre }}
                </option>
              </select>
            </div>

            <div v-if="selectedEmpresaId">
              <h3>Seleccione los Profesionales</h3>
              <div class="profesionales-list">
                <div v-for="profesional in profesionales" :key="profesional.id" class="profesional-item">
                  <input
                    type="checkbox"
                    :id="'profesional-' + profesional.id"
                    :value="profesional.id"
                    v-model="selectedProfesionalesIds"
                  />
                  <label :for="'profesional-' + profesional.id">
                    {{ profesional.nombre }} {{ profesional.apellido }} ({{ profesional.email }})
                  </label>
                </div>
              </div>
              <button class="btn-submit" @click="asignarProfesionales">Asignar Profesionales</button>
            </div>
          </div>
          <div v-else>
            Cargando datos...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'AsignarProfesionales',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      empresas: [],
      profesionales: [],
      selectedEmpresaId: '',
      selectedProfesionalesIds: [],
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['admin_datos']),
  },
  methods: {
    fetchData() {
      this.loaded = false;
      axios
        .all([
          axios.get(window.hostname + 'api/admin/get_empresas_administracion'),
          axios.get(window.hostname + 'api/admin/get_profesionales'),
        ])
        .then(
          axios.spread((empresasRes, profesionalesRes) => {
            this.empresas = empresasRes.data.empresas;
            this.profesionales = profesionalesRes.data.profesionales;
            this.loaded = true;
          })
        )
        .catch((e) => {
          console.log('Error al cargar datos:', e);
          this.loaded = true;
        });
    },
    onEmpresaChange() {
      // Al cambiar la empresa seleccionada, obtener los profesionales asignados actualmente
      axios
        .get(window.hostname + 'api/admin/get_profesionales_empresa', {
          params: { empresa_id: this.selectedEmpresaId },
        })
        .then((response) => {
          this.selectedProfesionalesIds = response.data.profesionales_ids;
        })
        .catch((e) => {
          console.log('Error al obtener profesionales de la empresa:', e);
        });
    },
    asignarProfesionales() {
      if (!this.selectedEmpresaId) {
        alert('Seleccione una empresa de administración.');
        return;
      }

      axios
        .post(window.hostname + 'api/admin/asignar_profesionales', {
          empresa_id: this.selectedEmpresaId,
          profesionales_ids: this.selectedProfesionalesIds,
        })
        .then(() => {
          alert('Profesionales asignados correctamente.');
        })
        .catch((e) => {
          console.log('Error al asignar profesionales:', e);
          alert('Ocurrió un error al asignar los profesionales.');
        });
    },
  },
  mounted() {
    if (!this.admin_datos || this.admin_datos.user_type !== 'jobbing_admin') {
      this.$router.push('/');
    } else {
      this.fetchData();
    }
  },
};
</script>

<style scoped>
.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.articulo-principal h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.form-group select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.profesionales-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
}

.profesional-item {
  margin-bottom: 10px;
}

.btn-submit {
  background-color: #3498db;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-submit:hover {
  background-color: #2980b9;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
</style>
