<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">
        <div class="articulo-principal">
          <h2>Gestión de Administradores de Propiedades</h2>
          <button @click="showAddForm = true" class="btn-add">Agregar Administrador de Propiedades</button>

          <!-- Formulario para agregar nuevo administrador -->
          <div v-if="showAddForm" class="form-container">
            <h3>Agregar Nuevo Administrador de Propiedades</h3>
            <form @submit.prevent="addPropertyManager">
              <div class="form-group">
                <label>Nombre:</label>
                <input v-model="newManager.nombre" type="text" required>
              </div>
              <div class="form-group">
                <label>Email:</label>
                <input v-model="newManager.email" type="email" required>
              </div>
              <div class="form-group">
                <label>Contraseña:</label>
                <input v-model="newManager.password" type="password" required>
              </div>
              <div class="form-group">
                <label>Teléfono:</label>
                <input v-model="newManager.telefono" type="text">
              </div>
              <div class="form-group">
                <label>Empresa de Administración:</label>
                <select v-model="newManager.empresa_id" required>
                  <option disabled value="">Seleccione una empresa</option>
                  <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">
                    {{ empresa.nombre }}
                  </option>
                </select>
              </div>
              <button type="submit" class="btn-save">Guardar</button>
              <button @click="showAddForm = false" class="btn-cancel">Cancelar</button>
            </form>
          </div>

          <!-- Tabla de administradores de propiedades -->
          <table class="tablex" v-if="loaded">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Empresa</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(manager, index) in propertyManagers" :key="manager.id">
                <td>{{ manager.id }}</td>
                <td>
                  <span v-if="!editing[index]">{{ manager.nombre }}</span>
                  <input v-else v-model="manager.nombre">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ manager.email }}</span>
                  <input v-else v-model="manager.email">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ manager.telefono }}</span>
                  <input v-else v-model="manager.telefono">
                </td>
                <td>
                  <span v-if="!editing[index]">{{ manager.empresa_nombre }}</span>
                  <select v-else v-model="manager.empresa_id">
                    <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">
                      {{ empresa.nombre }}
                    </option>
                  </select>
                </td>
                <td>
                  <button v-if="!editing[index]" @click="startEditing(index)">Editar</button>
                  <button v-if="editing[index]" @click="saveChanges(manager, index)">Guardar</button>
                  <button v-if="editing[index]" @click="cancelEditing(index)">Cancelar</button>
                  <button @click="deletePropertyManager(manager.id)">Eliminar</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="articulo-principal">
            Cargando...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'PropertyManagerManagement',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      propertyManagers: [],
      empresas: [],
      editing: [], // Seguimiento de las filas que están siendo editadas
      showAddForm: false,
      newManager: {
        nombre: '',
        email: '',
        password: '',
        telefono: '',
        empresa_id: '',
      },
    };
  },
  created() {
    this.fetchPropertyManagers();
    this.fetchEmpresas();
  },
  methods: {
    fetchPropertyManagers() {
      this.loaded = false;
      axios
        .get(window.hostname + 'api/superadmin/get_property_managers')
        .then((response) => {
          this.loaded = true;
          this.propertyManagers = response.data.property_managers;
          this.editing = this.propertyManagers.map(() => false); // Inicializar estado de edición para cada administrador
        })
        .catch((e) => {
          this.loaded = true;
          console.log('Error al cargar administradores de propiedades...', e);
        });
    },
    fetchEmpresas() {
      axios
        .get(window.hostname + 'api/superadmin/get_empresas_administracion')
        .then((response) => {
          this.empresas = response.data.empresas;
        })
        .catch((e) => {
          console.log('Error al cargar empresas de administración...', e);
        });
    },
    startEditing(index) {
      this.$set(this.editing, index, true);
    },
    cancelEditing(index) {
      this.$set(this.editing, index, false);
      this.fetchPropertyManagers(); // Revertir cambios locales
    },
    saveChanges(manager, index) {
      axios
        .post(window.hostname + 'api/superadmin/update_property_manager', manager)
        .then(() => {
          alert('Administrador de propiedades actualizado');
          this.$set(this.editing, index, false);
        })
        .catch((e) => {
          console.log('Error al actualizar administrador de propiedades', e);
        });
    },
    deletePropertyManager(managerId) {
      if (confirm('¿Estás seguro de que deseas eliminar este administrador de propiedades?')) {
        axios
          .post(window.hostname + 'api/superadmin/delete_property_manager', { id: managerId })
          .then(() => {
            alert('Administrador de propiedades eliminado');
            this.fetchPropertyManagers();
          })
          .catch((e) => {
            console.log('Error al eliminar administrador de propiedades', e);
          });
      }
    },
    addPropertyManager() {
      axios
        .post(window.hostname + 'api/superadmin/add_property_manager', this.newManager)
        .then(() => {
          alert('Administrador de propiedades agregado');
          this.showAddForm = false;
          this.newManager = {
            nombre: '',
            email: '',
            password: '',
            telefono: '',
            empresa_id: '',
          };
          this.fetchPropertyManagers();
        })
        .catch((e) => {
          console.log('Error al agregar administrador de propiedades', e);
        });
    },
  },
  computed: {
    ...mapGetters(['admin_datos']),
  },
  mounted() {
    if (!this.admin_datos.superadmin) {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
/* Estilos similares a los de tu componente existente */

.tablex th,
.tablex td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.tablex th {
  background-color: rgb(38, 53, 67);
  color: white;
}

.tablex {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.btn-add {
  background-color: #28a745;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-add:hover {
  background-color: #218838;
}

.btn-save {
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-save:hover {
  background-color: #0069d9;
}

.btn-cancel {
  background-color: #dc3545;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-cancel:hover {
  background-color: #c82333;
}

.form-container {
  background-color: #f8f9fa;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
</style>
