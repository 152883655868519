<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>
  

  <div class="column-right">
    <div v-if="admin_datos.superadmin" class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        
          <table>
    <thead>
      <tr>
        <th style="width:80px"></th>
        <th>Nombre</th>
        <th>Apellido</th>
        <th>Empresa</th>
        <th>Venta</th>
        <th>A pagar</th>
        <th>Acción</th>
      </tr>
    </thead>
    <tbody>

      <tr v-for="p in profesionales">
        <td><img :src="p.profesional.foto" :alt="p.profesional.nombre + ' '+p.profesional.apellido" class="person-img"></td>
        <td>{{p.profesional.nombre}}</td>
        <td>{{p.profesional.apellido}}</td>
        <td>
          <div v-if="p.profesional.empresa">
          {{p.profesional.empresa.nombre}}  <img :src="p.profesional.empresa.foto"  class="organization-img">
          </div>
          <div v-else>
            No tiene

          </div>
        </td>
        <td>${{Intl.NumberFormat('de-DE').format(p.monto_total)}}</td>
        <td>${{Intl.NumberFormat('de-DE').format(p.monto_a_pagar)}}</td>
        <td><button v-on:click="$router.push({path:'/superadmin/Transferir/'+p.profesional.id})" class="transfer-btn">Transferir</button></td>
      </tr>

    </tbody>
  </table>
        <button class="btn btn-std mt-2" v-on:click="downloadCSV()">Descargar CSV Transferencia Masiva <b-icon icon="download"></b-icon> </button>

        
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';


export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded:true,

    }
  },
  created(){
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

    downloadCSV() {

      console.log("JOJO")
      // Replace with the correct URL of your API
      const url = window.hostname+'/api/superadmin/generate_transfer_csv';

      // Make a GET request to the endpoint with responseType set to 'blob'
      axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
      })
        .then((response) => {
          // Create a new Blob object using the response data
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

          // Check for IE browser to handle download
          if (window.navigator.msSaveOrOpenBlob) {
            // For IE browsers
            window.navigator.msSaveBlob(blob, 'transfers.csv');
          } else {
            // For other browsers
            const link = document.createElement('a');
            if (link.download !== undefined) {
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'transfers.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch((error) => {
          console.error('Error downloading the CSV file:', error);
          // Handle error appropriately in your app
        });
    },
    initialize_(){

this.loaded = false;
axios.get(window.hostname+'api/superadmin/get_pendientes',)
.then(response => 
{
    this.loaded = true;
    this.profesionales = response.data.profesionales;

})

.catch(e=>{
    this.loaded = true;
    console.log("Error al cargar...", e)
})
},



  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  },
  mounted() {
    if(!this.admin_datos.superadmin)
    {
      this.$router.push('/')
    }
    
  },
  watch:{

  }

}
</script>


<style scoped>
.btn-std{
  background-color: #007bff !important;

}
.organization-img{
  max-width: 100px;
  max-height: 80px;
}
.person-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: rgb(38, 53, 67);;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.transfer-btn {
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.transfer-btn:hover {
  background-color: #0073a6;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

