<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div  class="main-container">
        <div class="articulo-principal">
          <h2>Transferencias Realizadas desde Jobbing</h2>
          
          <div v-if="loaded">
            <div v-if="monto_total_a_cobrar > 0">Actualmente, Jobbing le debe a {{ admin_datos.empresa.nombre }} la suma total de $<b>{{ Intl.NumberFormat('de-DE').format(monto_total_a_cobrar) }}</b>. </div>
          
            <!-- Tabla de transferencias -->
            <table class="tablex">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Monto</th>
    
                  <th>Fecha Creado</th>
      
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(transferencia, index) in transferencias">
                  <tr @click="toggleExpand(index)" :class="{ expanded: expandedIndex === index }">
                    <td>{{ transferencia.id }}</td>
                    <td>{{ transferencia.monto | currency }}</td>
                  
                    <td>{{ formatDate(transferencia.fecha_creado) }}</td>
                    <td>
                      <button class="download-button" @click.stop="downloadComprobante(transferencia.id)">Ver Comprobante</button>
                    </td>
                  </tr>
                  <!-- Detalle de pagos -->
                  <tr v-if="expandedIndex === index" class="detalle-pagos">
                    <td colspan="6">
                      <table class="tablex">
                        <thead>
                          <tr>
                            <th>ID Pago</th>
                            <th>Cliente</th>
                            <th>Direccion</th>
                            <th>Monto</th>
                            <th>Fecha Pagado</th>
                            <!-- Agrega más columnas si es necesario -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="pago in transferencia.pagos" :key="pago.id">
                            <td>{{ pago.id }}</td>
                            <td>{{ pago.orden.nombre }} {{ pago.orden.apellido1 }}</td>
                            <td>{{ pago.orden.direccion }}, {{ comunas.find(x=>x.id == pago.orden.comuna_id).Nombre }}</td>
                            <td>{{ pago.monto | currency }}</td>
                            <td>{{ formatDateTime(pago.fecha_pagado) }}</td>
                            <!-- Agrega más detalles del pago si es necesario -->
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-else class="articulo-principal">
            Cargando...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'TransferenciasCliente',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      transferencias: [],
      monto_total_a_cobrar:0,
      expandedIndex: null,
    };
  },
  created() {
    this.fetchTransferencias();
  },
  methods: {
    fetchTransferencias() {
      this.loaded = false;
      axios
        .get(window.hostname + 'api/property_manager/get_transferencias_empresa')
        .then((response) => {
          this.transferencias = response.data.transferencias;
          this.monto_total_a_cobrar = response.data.monto_total_a_cobrar;
          this.loaded = true;
        })
        .catch((e) => {
          this.loaded = true;
          console.error('Error al cargar transferencias:', e);
        });
    },
    toggleExpand(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
    formatDate(dateStr) {
      if (!dateStr) return 'N/A';
      const date = new Date(dateStr);
      return date.toLocaleDateString('es-ES');
    },
    formatDateTime(dateTimeStr) {
      if (!dateTimeStr) return 'N/A';
      const date = new Date(dateTimeStr);
      return date.toLocaleDateString("es-ES", { year: 'numeric', month: 'numeric', day: 'numeric' });
    },
    getEstado(estado) {
      // Map the estado integer to a human-readable string
      const estados = {
        1: 'Pendiente',
        2: 'Completado',
        3: 'Cancelado',
        // Agrega más estados si es necesario
      };
      return estados[estado] || 'Desconocido';
    },
    downloadComprobante(comprobante_id){
      axios({
        url: window.hostname+'api/property_manager/obtener_link_comprobante_transferencia',
        method: 'POST',
        data: {
          comprobante_id:comprobante_id,
        }
      })
      .then( (response) =>  {

        const anchor = document.createElement('a');

// Set the href attribute to the download URL
anchor.href = response.data.link;

// Set the download attribute to the filename
anchor.download = 'comprobante.'+response.data.ext;

  // Add the anchor element to the document
  document.body.appendChild(anchor);

  // Trigger a click event on the anchor element
  anchor.click();

  // Remove the anchor element from the document
  document.body.removeChild(anchor);         
      })
      .catch(e=>{
        console.log(e);
      })

    },


  },
  computed: {
    ...mapGetters(['admin_datos', 'comunas']),
  },
  mounted() {
    if (!this.admin_datos || this.admin_datos.user_type !== 'property_manager'){
      this.$router.push('/');
    }
  },
  filters: {
    currency(value) {
      if (typeof value !== 'number') {
        return value;
      }
      return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0,
      }).format(value);
    },
  },
};
</script>

<style scoped>
h2{
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}

.tablex th,
.tablex td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.tablex th {
  background-color: rgb(38, 53, 67);
  color: white;
}

.tablex {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.detalle-pagos {
  background-color: #f9f9f9;
}

.detalle-pagos td {
  padding: 0;
}

.expanded {
  background-color: #e8f4fc;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}

.download-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
}
</style>
