<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <ModalTransferir
      :pagos_="pagos"
      :tipos_de_cuenta_="tipos_de_cuenta"
      :bancos_="bancos"
      :monto_a_transferir="monto_a_transferir"
      :profesional_="profesional"
      ref="modaltransferir"
    />
    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">
        <div v-if="loaded" class="articulo-principal">

          Estos son todos los pagos que se le han hecho a <b> {{ profesional.nombre }} {{ profesional.apellido }}</b>  y que aún <b>no se han contabilizado como pagados</b>.
          <br/>
          <br/>
          <button
            v-on:click="()=>{if(monto_a_transferir > 0) {$refs.modaltransferir.show();}}"
            class="transfer-btn"
          >
            Transferir por ${{ Intl.NumberFormat('de-DE').format(monto_a_transferir) }}
          </button>
          <table>
            <thead>
              <tr>
                <th style="width:80px"></th>
                <th>A pagar</th>
                <th>Venta</th>
                <th>Insumos</th>
                <th>Presupuesto</th>
                <th>Orden ID</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Método de pago</th>
                <th>Fecha</th>
                <th>Fecha primera visita</th> <!-- Nueva columna -->
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="p in pagos">
                <tr
                  :title="getInformacionOrden(p.orden)"
                  v-on:click="(event)=> { 
                    if(!event.target.classList.contains('no-ver')) {
                      p.viendo_detalle = !p.viendo_detalle; 
                      $forceUpdate(); 
                    } 
                  }"
                >
                  <td>
                    <input
                      @change="cambio_checked()"
                      type="checkbox"
                      class="no-ver"
                      v-model="p.checked"
                    >
                  </td>

                  <!-- Comentado para referencia
                  <td>${{Intl.NumberFormat('de-DE').format(p.pago.monto*(1-profesional.margen_captacion) + p.pago.correcciones)}} </td>
                  -->
                  <td>${{ Intl.NumberFormat('de-DE').format(calcularTotalParaPago(p)) }}</td>
                  <td>${{ Intl.NumberFormat('de-DE').format(p.pago.monto) }}</td>
                  <td>${{ Intl.NumberFormat('de-DE').format(sumarEsInsumoParaPago(p)) }}</td>
                  <td>
                    <!-- {{p.orden.presupuestos}} -->
                    <div style="color: #FF4136; font-weight: 700;" v-if="p.orden.presupuestos.length == 0">
                      No ha enviado
                    </div>
                    <div v-else>
                      <div v-for="presupuesto in p.orden.presupuestos">
                        Enviado el {{ new Date(presupuesto.fecha_creado).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }} por ${{ Intl.NumberFormat('de-DE').format(presupuesto.monto_total) }} en estado <b>{{ estado_presupuestos[presupuesto.estado] }}</b>
                      </div>
                    </div>
                  </td>

                  <td>{{ p.pago.orden_id }}</td>
                  <td>{{ p.orden.nombre }} {{ p.orden.apellido1 }}</td>
                  <td>{{ p.orden.direccion }}, {{ comunas.find(z => z.id == p.orden.comuna_id).Nombre }}</td>
                  <td>{{ p.pago.metodo_pago }}</td>
                  <td>
                    <span v-if="p.pago.fecha_pagado">
                      {{ new Date(p.pago.fecha_pagado.y, p.pago.fecha_pagado.m - 1, p.pago.fecha_pagado.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}
                    </span>
                    <span v-else>
                      Indeterminado
                    </span>
                  </td>
                  <td>
                    <!-- Nueva columna: Fecha primera visita -->
                    <span v-if="getFechaPrimeraVisita(p.orden)">
                      {{ getFechaPrimeraVisita(p.orden) }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                  <td>
                    <button v-on:click="devolucion(p.pago.id)" class="eliminate-btn">
                      Marcar como devuelto
                    </button>
                    <!-- 
                    <button v-on:click="p.viendo_detalle = !p.viendo_detalle; $forceUpdate()" class="eliminate-btn">
                      ver detalle
                    </button> 
                    -->
                  </td>
                </tr>
                <tr style="background-color: #fff;" v-if="p.viendo_detalle">
                  <td style="border: 2px solid black;" colspan="12"> <!-- Actualizado el colspan -->
                    <div style="font-weight: 700;">Detalle</div>
                    <table>
                      <tr style="background-color: #fff;">
                        <td style="font-weight: 700;">
                          Nombre
                        </td>
                        <td style="font-weight: 700;">
                          ¿Es insumo?
                        </td>
                        <td style="font-weight: 700;">
                          ¿Es presupuesto?
                        </td>
                        <td style="font-weight: 700;">
                          Monto pagado
                        </td>
                        <td style="font-weight: 700;">
                          Monto total
                        </td>
                      </tr>
                      <tr style="background-color: #fff;" v-for="it in p.solicitud_pago.detalles">
                        <td>
                          {{ it.item.nombre }}
                        </td>
                        <td>
                          {{ it.item.es_insumo == 1 ? 'Si' : 'No' }}
                          <button v-on:click="actualizarItemEnPagos(it.item.id, it.item.es_insumo == 1 ? 0 : 1)" class="btn btn-info">
                            Cambiar
                          </button>
                        </td>
                        <td>
                          {{ it.item.es_presupuesto == 1 ? 'Si' : 'No' }}
                          <button v-on:click="actualizarItemEnPagos(it.item.id, null, it.item.es_presupuesto == 1 ? 0 : 1)" class="btn btn-info">
                            Cambiar
                          </button>
                        </td>
                        <td>
                          ${{ Intl.NumberFormat('de-DE').format(it.monto) }} ({{ (it.monto / it.item.precio) * 100 }}%)
                        </td>
                        <td>
                          ${{ Intl.NumberFormat('de-DE').format(it.item.precio) }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

        </div>
        <div v-else class="articulo-principal">
          cargando...
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalTransferir from '@/views/superadmin/ModalTransferir.vue';
export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    ModalTransferir,
    TopNav,
  },
  data() {
    return {
      loaded:true,
      profesional:{},
      pagos:[],
      bancos:[],
      tipos_de_cuenta:[],
      monto_a_transferir:0,
      estado_presupuestos:{
        1:'Enviado',
        2:'Aprobado',
      }

    }
  },
  created(){
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),
    getFechaPrimeraVisita(orden) {
      if (!orden.visitas || orden.visitas.length === 0) return '';
      
      // Convertir las fechas a objetos Date y encontrar la más temprana
      const fechas = orden.visitas
        .map(v => new Date(v.fecha_))
        .filter(date => !isNaN(date)); // Filtrar fechas inválidas
      
      if (fechas.length === 0) return '';
      
      const earliest = fechas.reduce((a, b) => (a < b ? a : b));
      
      // Formatear la fecha en español
      return earliest.toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' });
    },
    getInformacionOrden(orden){

      let ret = `Orden: \nVisitas: \n`
      for (let v of orden.visitas){
        console.log(v)
        ret += ' - '+ new Date(v.fecha.a, v.fecha.m, v.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })+', a las '+this.timeConvert(v.desde)+' hrs ('+(v.estado == 4 ? 'atendida' : 'pendiente')+') \n'
      }
      return ret;
    },
    calcularTotalParaPago(pago) {
      let total = 0;
      const margenCaptacion = this.profesional.margen_captacion;
    if(pago.solicitud_pago && pago.solicitud_pago.detalles)
    {

        total = pago.solicitud_pago.detalles.reduce((accumulator, detalle) => {
        const esInsumo = detalle.item.es_insumo === 1;
        
        const monto = detalle.monto;

        const multiplicador = esInsumo ? (1-0.03) : (1 - margenCaptacion);
        return accumulator + (monto * multiplicador);
      }, 0);
      return total;
    }
    return pago.pago.monto * (1 - margenCaptacion);
    
  },

      timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },

  sumarEsInsumoParaPago(pago) {
    if(pago.solicitud_pago && pago.solicitud_pago.detalles)
    {
      const totalEsInsumo = pago.solicitud_pago.detalles.reduce((accumulator, detalle) => {
        return accumulator + (detalle.item.es_insumo ? detalle.monto : 0);
      }, 0);

      return totalEsInsumo;
    }
    return 0;

  },
    actualizarItemEnPagos(item_id, es_insumo = null, es_presupuesto = null, es_reembolsable = null) {
    // Find the specific item in the pagos array
    const item = this.pagos.find(pago => 
      pago.solicitud_pago.detalles.some(detalle => detalle.item_id === item_id)
    );

    if (item) {
      const detalle = item.solicitud_pago.detalles.find(detalle => detalle.item_id === item_id);

      // Prepare the data to be sent to the server
      const dataToUpdate = {
        item_id: item_id,
        es_insumo: es_insumo !== null ? es_insumo : detalle.item.es_insumo,
        es_presupuesto: es_presupuesto !== null ? es_presupuesto : detalle.item.es_presupuesto,
        es_reembolsable: es_reembolsable !== null ? es_reembolsable : detalle.item.es_reembolsable
      };

      // Make the API call to update the item on the server
      axios.post(window.hostname+'/api/superadmin/cambiar_item', dataToUpdate)
        .then(response => {
          // Update the item locally in the pagos array
          if (es_insumo !== null) {
            detalle.item.es_insumo = es_insumo;
          }
          if (es_presupuesto !== null) {
            detalle.item.es_presupuesto = es_presupuesto;
          }
          if (es_reembolsable !== null) {
            detalle.item.es_reembolsable = es_reembolsable;
          }
          this.recalcular_monto_a_transferir()
          this.$forceUpdate()

          // Handle the successful response (e.g., show a success message)
          console.log('Item updated successfully:', response.data);
        })
        .catch(error => {
          // Handle the error (e.g., show an error message)
          console.error('Error updating item:', error.response ? error.response.data : error.message);
        });
    } else {
      console.error('Item not found in pagos array');
    }
  },
    cambio_checked(){
      this.recalcular_monto_a_transferir()
    },
    recalcular_monto_a_transferir() {
          var tot = 0;
          console.log("CAMBIO")
          for(var m of this.pagos){
          if(m.checked)
            tot += this.calcularTotalParaPago(m)
          }
          this.monto_a_transferir = tot;
          console.log("TOT", tot)
        },
    initialize_(){

        this.loaded = false;
        axios.post(window.hostname+'api/superadmin/initialize_transferir', {profesional_id:this.$route.params.id})
        .then(response => 
        {
            this.loaded = true;
            this.pagos = response.data.pagos;
            this.bancos = response.data.bancos;
            this.tipos_de_cuenta = response.data.tipos_de_cuenta;
            this.profesional = response.data.profesional
            for(var p of this.pagos){
              p.checked = true;
              p.viendo_detalle = false;
              console.log(p)
            }
            this.recalcular_monto_a_transferir()

        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...", e)
        })
    },
    devolucion(pago_id){

        axios.post(window.hostname+'api/superadmin/marcar_como_pago_devolucion', {pago_id:pago_id})
        .then(response => 
        {
          this.$notify({duration:5000, group: 'foos', title: '¡Bien!', text: '¡Se cambio el estado!', type: 'success'})

          this.initialize_()

        })

        .catch(e=>{
          this.$notify({duration:5000, group: 'foos', title: 'Error!', text: e.response.data.message, type: 'error'})
        })
    },



  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
    monto_a_transferir(){
      var tot = 0;
      for(var m of this.pagos){
       if(m.checked)
        tot += this.calcularTotalParaPago(m)
      }
      return tot
    }
  },
  mounted() {
    if(!this.admin_datos.superadmin)
    {
      this.$router.push('/')
    }
    this.$root.$on('update-pagos', () => {      
      this.initialize_()
    
    })
    
  },
  watch:{

  }

}
</script>


<style scoped>

.transfer-btn {
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;

  cursor: pointer;
  border-radius: 4px;
}

.transfer-btn:hover {
  background-color: #0073a6;
}
.organization-img{
  max-width: 100px;
  max-height: 80px;
}
.person-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 4px 0;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: rgb(38, 53, 67);
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.eliminate-btn {
  background-color: #FF4136;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.eliminate-btn:hover {
  background-color: #fd746c;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

