<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div class="main-container">
        <div class="articulo-principal">
          <h2>Crear Nueva Solicitud</h2>
          <form @submit.prevent="crearSolicitud" enctype="multipart/form-data">
            <!-- Información del Usuario -->
            <div class="sectionv">
              <h3>Información del Usuario 🧑</h3>
              <div class="form-group">
                <label>Nombre: <i style="font-weight: 500;">(nombre de la persona que recibirá las solicitudes)</i></label>
                <input v-model="usuario.nombre" type="text" required>
              </div>
              <div class="form-group">
                <label>Email: <i style="font-weight: 500;">(email de la persona que recibirá las solicitudes)</i></label>
                <input v-model="usuario.email" type="email" required>
              </div>
              <div class="form-group">
                <label>Teléfono: <i style="font-weight: 500;">(teléfono de la persona que recibirá las solicitudes)</i></label>
                <input v-model="usuario.telefono" type="text" required>
              </div>
            </div>
            <!-- Información de la Solicitud -->
            <div class="sectionv">
              <h3>Detalles de la Solicitud 📝</h3>
              <div class="form-group">
                <label>Título:</label>
                <input v-model="solicitud.titulo" type="text" required>
              </div>
              <div class="form-group">
                <label>Descripción del Problema:</label>
                <textarea v-model="solicitud.descripcion" required></textarea>
              </div>
              <div class="form-group">
                <label>Dirección:</label>
                <input v-model="solicitud.direccion" type="text" required>
              </div>
              <div class="form-group">
                <label>Comuna:</label>
                <select v-model="solicitud.comuna_id" required>
                  <option disabled value="">Seleccione una comuna</option>
                  <option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">
                    {{ comuna.Nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Categoría:</label>
                <select v-model="solicitud.categoria_id" required>
                  <option disabled value="">Seleccione una categoría</option>
                  <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">
                    {{ categoria.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Prioridad:</label>
                <select v-model="solicitud.prioridad" required>
                  <option disabled value="">Seleccione una prioridad</option>
                  <option value="alta">Alta</option>
                  <option value="media">Media</option>
                  <option value="baja">Baja</option>
                </select>
              </div>
              <div class="form-group">
                <label>Fotos:</label>
                <input type="file" multiple @change="onFileChange">
              </div>
            </div>
            <!-- Agrega otros campos necesarios para la solicitud -->
            <button type="submit" class="btn btn-std">Crear Solicitud <b-icon icon="star"></b-icon></button>
          </form>
        </div>
      </div>
    </div>

    <!-- Loading Overlay -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'CrearSolicitud',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      usuario: {
        nombre: '',
        email: '',
        telefono: '',
      },
      solicitud: {
        titulo: '',
        descripcion: '',
        categoria_id: '',
        comuna_id: '',
        prioridad: '',
        direccion: '',
        // Otros campos necesarios
      },
      fotos: [], // Array para almacenar las fotos seleccionadas
      isLoading: false, // Estado de carga
    };
  },
  computed: {
    ...mapGetters(['admin_datos', 'categorias', 'comunas']),
  },
  methods: {
    onFileChange(e) {
      this.fotos = Array.from(e.target.files);
    },
    crearSolicitud() {
      // Validaciones básicas
      if (
        !this.usuario.nombre ||
        !this.usuario.email ||
        !this.usuario.telefono ||
        !this.solicitud.titulo ||
        !this.solicitud.descripcion ||
        !this.solicitud.categoria_id ||
        !this.solicitud.comuna_id ||
        !this.solicitud.direccion ||
        !this.solicitud.prioridad
      ) {
        alert('Por favor, complete todos los campos obligatorios.');
        return;
      }

      this.isLoading = true; // Inicia el estado de carga

      const formData = new FormData();
      // Datos del usuario
      formData.append('nombre', this.usuario.nombre);
      formData.append('email', this.usuario.email);
      formData.append('telefono', this.usuario.telefono);
      // Datos de la solicitud
      formData.append('titulo', this.solicitud.titulo);
      formData.append('descripcion', this.solicitud.descripcion);
      formData.append('categoria_id', this.solicitud.categoria_id);
      formData.append('comuna_id', this.solicitud.comuna_id);
      formData.append('prioridad', this.solicitud.prioridad);
      formData.append('direccion', this.solicitud.direccion);
      // Agrega otros campos a formData según sea necesario

      // Añadir las fotos al FormData
      this.fotos.forEach((foto, index) => {
        formData.append(`foto${index}`, foto);
      });

      axios
        .post(window.hostname + 'api/property_manager/crear_solicitud', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.isLoading = false; // Finaliza el estado de carga
          alert('Solicitud creada exitosamente');
          // Redirección removida
          // this.$router.push('/ruta/donde/deseas/redirigir');
        })
        .catch((e) => {
          this.isLoading = false; // Finaliza el estado de carga incluso si hay error
          console.log('Error al crear la solicitud', e);
          alert('Ocurrió un error al crear la solicitud');
        });
    },
  },
  mounted() {
    if (!this.admin_datos || this.admin_datos.user_type !== 'property_manager') {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
/* Estilos similares a los de tus componentes existentes */
.btn-std {
  background-color: #007bff !important;
}

h2 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}

.sectionv {
  background-color: rgb(250, 250, 250);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.btn-save {
  background-color: #28a745;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-save:hover {
  background-color: #218838;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}

.crear-solicitud-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  display: flex;
  flex: 1;
}

.main-content {
  flex: 1;
  background-color: #f0f2f5;
  padding: 80px 20px 20px;
}

.form-container {
  background-color: white;
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.form-container h3 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #3498db;
  outline: none;
}

.form-group textarea {
  resize: vertical;
}

.form-group input[type='file'] {
  padding: 3px;
}

.btn-submit {
  display: block;
  width: 100%;
  background-color: #3498db;
  color: white;
  padding: 14px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.btn-submit:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .main-content {
    padding: 20px;
  }

  .form-container {
    padding: 30px;
  }
}

/* Estilos para la pantalla de carga */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparente negro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 12px solid #f3f3f3; /* Gris claro */
  border-top: 12px solid #3498db; /* Azul */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
