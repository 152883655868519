<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>
  
    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">       
        <div v-if="loaded" class="articulo-principal">
          <p>Estas son las aplicaciones recibidas en la plataforma.</p>
          <table class="tablex">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Fecha de Aplicación</th>
                <th>Revisado</th>
                <th>Comentarios</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <template
              v-for="(app, index) in applications"               
                v-if="app && app.id"
                >
              <tr :key="app.id" :title="`Razón: ${app.porque || 'No especificado'}\nExperiencia: ${app.experiencia || 'No especificada'}`"
               
              @click="toggleDetails(app.id)" 
              :class="{'clickable-row': true}"     >
                <td>{{ app.id }}</td>
                <td>{{ app.nombre }} {{ app.apellido }}</td>
                <td>{{ app.email }}</td>
                <td>{{ app.telefono }}</td>
                <td>{{ formatDate(app.created_at) }}</td>
                <td @click.stop="markAsReviewed(app.id, app.reviewed)">
                  <input type="checkbox" v-model="app.reviewed">
                </td>
                <td @click.stop>
                  <span v-if="!editing[app.id]">{{ app.commentaries || 'Sin comentarios' }}</span>
                  <input 
                    v-else 
                    v-model="app.commentaries" 
                    @blur="saveComentarios(app.id, app.commentaries)"
                  >
                  <button v-if="!editing[app.id]" @click.stop="startEditing(app.id)">Editar</button>
                  <button v-if="editing[app.id]" @click.stop="cancelEditing(app.id)">Cancelar</button>
                </td>
                <td @click.stop>
                  <button @click.stop="assignProfessional(app.id)">Asignar Profesional</button>
                </td>
              </tr>
              <!-- Detail Row -->
              <tr v-if="app && app.id && isRowExpanded(app.id)" class="detail-row"  >
                <td colspan="8">
                  <strong>Razón:</strong> {{ app.porque || 'No especificado' }}<br>
                  <strong>Experiencia:</strong> {{ app.experiencia || 'No especificada' }}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <div v-else class="articulo-principal">
          Cargando...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'ApplicationManagement',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      applications: [],
      editing: {}, // Track editing state by application ID
      expandedRows: [], // Use array for better reactivity in Vue 2
    };
  },
  created() {
    this.fetchApplications();
  },
  methods: {
    toggleDetails(appId) {
      if (!appId) {
        console.warn('toggleDetails called with undefined appId');
        return;
      }
      const index = this.expandedRows.indexOf(appId);
      if (index > -1) {
        // Remove from expandedRows
        this.expandedRows.splice(index, 1);
      } else {
        // Add to expandedRows
        this.expandedRows.push(appId);
      }
    },
    isRowExpanded(appId) {
      return this.expandedRows.includes(appId);
    },
    fetchApplications() {
      this.loaded = false;
      axios
        .get(`${window.hostname}api/superadmin/get_applications`)
        .then((response) => {
          this.loaded = true;
          if (response.data && Array.isArray(response.data.applications)) {
            // Filter out any undefined or malformed application objects
            this.applications = response.data.applications
              .filter(app => app && app.id)
              .reverse();
            // Initialize editing state for each application
            this.applications.forEach(app => {
              this.$set(this.editing, app.id, false);
            });
          } else {
            console.error('Invalid data structure for applications:', response.data);
            this.applications = [];
          }
        })
        .catch((e) => {
          this.loaded = true;
          console.error('Error al cargar aplicaciones...', e);
        });
    },
    markAsReviewed(applicationId, reviewed) {
      if (!applicationId) {
        console.warn('markAsReviewed called with undefined applicationId');
        return;
      }
      axios
        .post(`${window.hostname}api/superadmin/mark_as_reviewed`, { id: applicationId, reviewed })
        .then(() => {
          alert('Aplicación marcada como revisada');
        })
        .catch((e) => {
          console.error('Error al marcar como revisado', e);
        });
    },
    assignProfessional(applicationId) {
      if (!applicationId) {
        console.warn('assignProfessional called with undefined applicationId');
        return;
      }
      alert('Función para asignar un profesional no implementada.');
    },
    startEditing(appId) {
      if (!appId) {
        console.warn('startEditing called with undefined appId');
        return;
      }
      this.$set(this.editing, appId, true);
    },
    cancelEditing(appId) {
      if (!appId) {
        console.warn('cancelEditing called with undefined appId');
        return;
      }
      this.$set(this.editing, appId, false);
      // Optionally, reload or revert the commentaries if needed
    },
    saveComentarios(applicationId, newComentarios) {
      if (!applicationId) {
        console.warn('saveComentarios called with undefined applicationId');
        return;
      }
      axios
        .post(`${window.hostname}api/superadmin/update_comentarios`, { id: applicationId, comentarios: newComentarios })
        .then(() => {
          alert('Comentarios actualizados');
          this.$set(this.editing, applicationId, false); // Exit editing mode once saved
        })
        .catch((e) => {
          console.error('Error al actualizar comentarios', e);
        });
    },
    formatDate(dateString) {
      if (!dateString) return 'Fecha no disponible';
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
  computed: {
    ...mapGetters(['admin_datos']),
  },
  mounted() {
    if (!this.admin_datos.superadmin) {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
.tablex th, .tablex td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.tablex th {
  background-color: rgb(38, 53, 67);
  color: white;
}

.tablex {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.servicios {
  text-align: left;
}

.es-pagado {
  background-color: rgba(46, 204, 64, 0.1);
  border-radius: 4px; 
  color: #2ECC40; 
  display: inline-block; 
  padding: 2px 6px;
  margin-left: 5px;
}

.es-presupuesto {
  background-color: rgba(0,116,217, 0.1);
  border-radius: 4px; 
  color: rgb(0,116,217); 
  display: inline-block; 
  padding: 2px 6px;
  margin-left: 5px;
}

.es-insumo {
  background-color: rgba(240,18,190, 0.1);
  border-radius: 4px; 
  color: rgb(240,18,190); 
  display: inline-block; 
  padding: 2px 6px;
  margin-left: 5px;
}

.input-cont {
  position: relative;
  text-align: left;
  max-width: 400px;
  margin-bottom: 40px;
}

.input-cont input {
  padding: 4px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.4);
  width: 100%;
  outline: none;
}

.input-label {
  position: absolute;
  top: 3px;
  left: 6px;
  pointer-events: none;
  color: rgba(0,0,0,0.6);
  transition: 0.2s;
}

.subido {
  top: -12px;
  left: 0px;
  font-size: 12px;
  color: #FF8C00;
}

.subido2 {
  top: -16px;
  left: -84px;
  font-size: 12px;
  color: #FF8C00;
}

.btn-casa-depto {
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #FF8C00;
  margin-right: 10px;
  color: #FF8C00;
  border-radius: 5px;
  cursor: pointer;
}

.btn-casa-depto:hover {
  color: white;
  background: #FF8C00;
}

.btn-casa-depto-s {
  color: white;
  background: #FF8C00;
}

td {
  text-align: left;
}

/* Remove duplicate td styles */

.articulo-principal {
  /* Add any necessary styles */
}

table { 
  margin-left: auto;
  margin-right: auto;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px 20px 10px 20px;
}

.detail-row td {
  background-color: #f9f9f9;
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #f1f1f1;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
</style>
