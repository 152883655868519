<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">
        <div class="articulo-principal">
          <h2>Comisiones Pendientes a Empresa de Administración</h2>

          <!-- Tabla de empresas de administración -->
          <table class="tablex" v-if="loaded">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Dirección</th>
                <th>Teléfono</th>
                <th>Email</th>
                <th>Sitio Web</th>
                <th>Monto Total Pendiente</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(empresa, index) in empresas" >
                <tr
                  @click="toggleExpand(index)"
                  :class="{ expanded: expandedIndex === index }"
                >
                  <td>{{ empresa.empresa.nombre }}</td>
                  <td>{{ empresa.empresa.direccion }}</td>
                  <td>{{ empresa.empresa.telefono }}</td>
                  <td>{{ empresa.empresa.email }}</td>
                  <td>{{ empresa.empresa.website }}</td>
                  <td>{{ calcularMontoTotal(empresa.pagos)*empresa.empresa.margen_captacion | currency }}</td>
                  <td>
                    <button class="download-button" @click.stop="openTransferModal(index)">Transferir</button>
                  </td>
                </tr>
                <!-- Detalle de pagos y órdenes -->
                <tr v-if="expandedIndex === index" class="detalle-pagos">
                  <td colspan="7">
                    <table class="tablex">
                      <thead>
                        <tr>
                          <th><input type="checkbox" @change="toggleAllPagos(index)" v-model="allPagosChecked[index]" /></th>
                          <th>ID Pago</th>
                          <th>Monto</th>
                          <th>Comisión</th>
                          <th>Fecha Pagado</th>
                          <th>Detalles de Orden</th>
                          <th>Método de Pago</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(pago, pagoIndex) in empresa.pagos" :key="pago.id">
                          <td><input type="checkbox" v-model="pago.checked" @change="updateMontoTotal(index)" /></td>
                          <td>{{ pago.id }}</td>
                          <td>{{ pago.monto | currency }}</td>
                          <td>{{ pago.monto*empresa.empresa.margen_captacion | currency }}</td>
                          <td>{{ formatFechaPagado(pago.fecha_pagado) }}</td>
                          <td>
                            <div v-if="pago.orden">
                              <div><b>Cliente:</b> {{ pago.orden.nombre }} {{ pago.orden.apellido1 }}</div>
                              <div><b>Dirección:</b> {{ pago.orden.direccion }}, {{comunas.find(x=>x.id == pago.orden.comuna_id).Nombre}}</div>
                            </div>
                            <div v-else> N/A</div>
                          </td>
 
                          <td>{{ pago.metodo_pago }}</td>
                          <td>
                            <div v-if="pago.estado == 1">
                              <b style="color: #2ECC40;">Pagado</b>
                            </div>
                            <div v-if="pago.estado == 0">
                              <b >No Pagado</b>
                            </div>
                            <div v-if="pago.estado == 2">
                              <b style="color: #FF4136;" >Devuelto</b>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-else class="articulo-principal">
            Cargando...
          </div>

          <!-- Transfer Modal -->
          <div v-if="showTransferModal" class="modall">
            <div class="modal-content">
              <span class="close" @click="closeTransferModal">&times;</span>
              <h2>Transferir Comisiones a {{ selectedEmpresa.empresa.nombre }}</h2>
              <form @submit.prevent="submitTransfer">
                <div class="form-group">
                  <label for="nombre">Nombre:</label>
                  <input type="text" v-model="transferData.nombre" required />
                </div>
                <div class="form-group">
                  <label for="rut">RUT:</label>
                  <input type="text" v-model="transferData.rut" required />
                </div>
                <div class="form-group">
                  <label for="banco_id">Banco:</label>
                  <select v-model="transferData.banco_id" required>
                    <option v-for="b, i in bancos" :value="i">{{b}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="tipo_cuenta_id">Tipo de Cuenta:</label>
                  <select v-model="transferData.tipo_cuenta_id" required>
                    <option v-for="b, i in tipos_de_cuenta" :value="i">{{b}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="cuenta_bancaria">Cuenta Bancaria:</label>
                  <input type="text" v-model="transferData.cuenta_bancaria" required />
                </div>
                <div class="form-group">
                  <label for="archivo">Comprobante de Transferencia:</label>
                  <input type="file" @change="handleFileUpload" required />
                </div>
                <button type="submit">Enviar Transferencia</button>
                <button type="button" @click="closeTransferModal">Cancelar</button>
              </form>
            </div>
          </div>
          <!-- Modal Overlay -->
          <div v-if="showTransferModal" class="modal-overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'PendienteComisionEmpresa',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      empresas: [],
      bancos: [],
      tipos_de_cuenta: [],
      expandedIndex: null,
      allPagosChecked: {},
      showTransferModal: false,
      selectedEmpresaIndex: null,
      selectedEmpresa: null,
      transferData: {
        nombre: '',
        rut: '',
        banco_id: '',
        tipo_cuenta_id: '',
        cuenta_bancaria: '',
      },
      comprobante: null,
      loading: false,
      status: 0, // 0: idle, 1: success, 2: error
    };
  },
  created() {
    this.fetchEmpresas();
  },
  methods: {
    fetchEmpresas() {
      this.loaded = false;
      axios
        .get(window.hostname + 'api/superadmin/pendiente_comision_empresa')
        .then((response) => {
          this.loaded = true;
          // Initialize the checked state for each pago
          this.empresas = response.data.empresas.map((empresa, index) => {
            empresa.pagos = empresa.pagos.map((pago) => {
              return { ...pago, checked: true }; // checked by default
            });
            this.allPagosChecked[index] = true; // all pagos checked by default
            return empresa;
          });
          // Load bancos and tipos_de_cuenta
          this.bancos = response.data.bancos;
          this.tipos_de_cuenta = response.data.tipos_de_cuenta;
        })
        .catch((e) => {
          this.loaded = true;
          console.log('Error al cargar comisiones pendientes...', e);
        });
    },
    toggleExpand(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
    calcularMontoTotal(pagos) {
      return pagos.reduce((total, pago) => (pago.checked ? total + pago.monto : total), 0);
    },
    formatFechaPagado(fechaPagado) {
      if (!fechaPagado) {
        return 'N/A';
      }
      const { y, m, d } = fechaPagado;
      const date = new Date(y, m - 1, d);
      return date.toLocaleDateString('es-ES');
    },
    toggleAllPagos(index) {
      const checkAll = this.allPagosChecked[index];
      this.empresas[index].pagos.forEach((pago) => {
        pago.checked = checkAll;
      });
      this.updateMontoTotal(index);
    },
    updateMontoTotal(index) {
      // Re-calculate the total amount for the empresa
      // This will automatically update the displayed total due to reactivity
    },
    openTransferModal(index) {
      this.selectedEmpresaIndex = index;
      this.selectedEmpresa = this.empresas[index];
      this.showTransferModal = true;
      console.log(this.selectedEmpresa)
      this.transferData.nombre = this.selectedEmpresa.empresa.nombre;
      this.transferData.rut = this.selectedEmpresa.empresa.rut;;
      this.transferData.banco_id = this.selectedEmpresa.empresa.banco_id;
      this.transferData.tipo_cuenta_id = this.selectedEmpresa.empresa.tipo_cuenta_id;
      this.transferData.cuenta_bancaria = this.selectedEmpresa.empresa.cuenta_bancaria;
      
    },
    closeTransferModal() {
      this.showTransferModal = false;
      this.transferData = {
        nombre: '',
        rut: '',
        banco_id: '',
        tipo_cuenta_id: '',
        cuenta_bancaria: '',
      };
      this.comprobante = null;
    },
    handleFileUpload(event) {
      this.comprobante = event.target.files[0];
    },
    submitTransfer() {
      this.loading = true;
      let pagos = [];
      for (let p of this.selectedEmpresa.pagos) {
        if (p.checked) {
          pagos.push({ id: p.id });
        }
      }
      if (pagos.length === 0) {
        alert('Debe seleccionar al menos un pago para transferir.');
        this.loading = false;
        return;
      }
      const json_ob = JSON.stringify({
        nombre: this.transferData.nombre,
        rut: this.transferData.rut,
        empresa_id: this.selectedEmpresa.empresa.id,
        banco_id: this.transferData.banco_id,
        tipo_cuenta_id: this.transferData.tipo_cuenta_id,
        cuenta_bancaria: this.transferData.cuenta_bancaria,
        pagos: pagos,
      });
      const blob = new Blob([json_ob], { type: 'application/json' });
      let data = new FormData();
      data.append('document', blob);
      data.append('file', this.comprobante);

      axios
        .post(window.hostname + 'api/superadmin/ingresar_transferencia_empresa', data)
        .then((r) => {
          this.status = 1;
          this.loading = false;
          this.$root.$emit('update-pagos');
          alert('Transferencia registrada exitosamente.');
          this.closeTransferModal();
          this.fetchEmpresas(); // Refresh data
        })
        .catch((e) => {
          this.status = 2;
          this.loading = false;
          console.error('Error al registrar transferencia:', e);
          alert('Hubo un error al registrar la transferencia.');
        });
    },
  },
  computed: {
    ...mapGetters(['admin_datos', 'comunas']),
  },
  filters: {
    currency(value) {
      if (typeof value !== 'number') {
        return value;
      }
      return new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0,
      }).format(value);
    },
  },
  mounted() {
    if (!this.admin_datos.superadmin) {
      this.$router.push('/');
    }
  },
};
</script>



<style scoped>
.tablex th,
.tablex td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.tablex th {
  background-color: rgb(38, 53, 67);
  color: white;
}

.tablex {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.detalle-pagos {
  background-color: #f9f9f9;
}

.detalle-pagos td {
  padding: 0;
}

.expanded {
  background-color: #e8f4fc;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}

/* Modal styles */
.modall {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1001;
  width: 500px;
  max-width: 90%;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

.close {
  float: right;
  font-size: 24px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
}

.form-group input[type="text"],
.form-group select,
.form-group input[type="file"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.download-button {
  background-color: #007bff; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 16px; /* Button padding */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer on hover */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline for link buttons */
  display: inline-block;
  white-space: nowrap;
}
</style>

